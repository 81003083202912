<template>
  <div class="jira_page">
    <div class="fixed">
      <div class="header">
        <div class="left" @click="openFilter">
          <van-icon name="share-o" /> 跳转客户 Jira Filter Dashboard
        </div>
        <div class="right">
          <van-button
            icon="replay"
            round
            type="primary"
            :class="
              state.refreshing ? 'refreshBtn animation-rotating' : 'refreshBtn'
            "
            :disabled="state.refreshing"
            size="mini"
            @click="onRefresh"
          />
          <van-button
            icon="plus"
            round
            type="primary"
            class="btn"
            size="mini"
            @click="
              () => openUrl('https://jira.agoralab.co/projects/CSD/issues')
            "
          />
        </div>
      </div>
      <van-dropdown-menu active-color="#2c90f3" z-index="1" duration="0.2">
        <van-dropdown-item v-model="state.stage" :options="stageOpts" />
        <van-dropdown-item
          v-model="state.priority"
          :options="priorityOpts"
          title-class="priority-option"
        />
        <van-dropdown-item
          v-model="state.vid"
          :options="state.vidOpts"
          @change="onVidChange"
        />
      </van-dropdown-menu>
    </div>
    <van-loading
      type="spinner"
      size="40"
      v-if="state.loading"
      style="margin-top: 40px"
    />
    <ul class="jira_list" v-else>
      <li v-for="item in state.data" :key="item.key">
        <div class="key" @click="openJira(item.key)">{{ item.key }}</div>
        <div class="title" @click="openTitle(item.fields.summary)">
          {{ item.fields.summary }}
        </div>
        <img class="icon" :src="priorityIconMap[item.fields?.priority?.name]" />
        <div class="date">{{ item.fields.created?.slice(5, 10) }}</div>
      </li>
    </ul>
    <van-popup v-model:show="state.showPopup" @close="closePopup">
      <div class="popup_title">
        {{ state.title }}
      </div>
    </van-popup>
  </div>
</template>
<script lang="ts">
import { useStore } from "@/store";
import { openJira, openUrl } from "@/utils";
import {
  computed,
  ComputedRef,
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "vue";
import { Issue } from "./service";
const stageOpts = [
  {
    text: "未解决",
    value: "未解决",
  },
  {
    text: "已解决",
    value: "已解决",
  },
];

const stageDic: { [key: string]: string[] } = {
  未解决: ["new", "In Progress", "Escalated", "Pending on Support", "Reopened"],
  已解决: [
    "Resolved",
    "Declined",
    "Closed",
    "QA Verified",
    "Waiting for customer",
  ],
};

const priorityOpts = [
  { text: "优先级", value: "优先级" },
  {
    text: "Blocker",
    value: "Blocker",
    icon: "https://agora-web-cdn.oss-cn-beijing.aliyuncs.com/log-analyzer/static/images/priority/blocker.svg",
  },
  {
    text: "Highest",
    value: "Highest",
    icon: "https://agora-web-cdn.oss-cn-beijing.aliyuncs.com/log-analyzer/static/images/priority/major.svg",
  },
  {
    text: "High",
    value: "High",
    icon: "https://agora-web-cdn.oss-cn-beijing.aliyuncs.com/log-analyzer/static/images/priority/critical.svg",
  },
  {
    text: "Medium",
    value: "Medium",
    icon: "https://agora-web-cdn.oss-cn-beijing.aliyuncs.com/log-analyzer/static/images/priority/medium.svg",
  },
  {
    text: "Low",
    value: "Low",
    icon: "https://agora-web-cdn.oss-cn-beijing.aliyuncs.com/log-analyzer/static/images/priority/low.svg",
  },
  {
    text: "Minor",
    value: "Minor",
    icon: "https://agora-web-cdn.oss-cn-beijing.aliyuncs.com/log-analyzer/static/images/priority/minor.svg",
  },
];

const priorityIconMap = priorityOpts.reduce((pre, next) => {
  pre[next.value] = next.icon;
  return pre;
}, {} as any);

const vidOpts = [
  {
    text: "vid",
    value: "vid",
  },
];
type DataWatchT = [string, string, string, Issue[]];
export default defineComponent({
  name: "jira",
  setup() {
    const store = useStore();

    const state = reactive<{
      loading: ComputedRef<boolean>;
      stage: string;
      priority: string;
      title: string;
      data: Issue[];
      refreshing: boolean;
      showPopup: boolean;
      vid: string;
      vidOpts: ComputedRef<{ text: string; value: string }[]>;
      jql: string;
    }>({
      loading: computed(() => store.state.jira.loading),
      refreshing: false,
      stage: stageOpts[0].value,
      priority: priorityOpts[0].value,
      vid: vidOpts[0].value,
      title: "",
      jql: "",
      data: [],
      showPopup: false,
      vidOpts: computed(() => {
        const { vidOptions } = store.state;
        return [...vidOpts, ...vidOptions];
      }),
    });

    const onVidChange = (v: string) => {
      store.commit("setVID", v);
    };

    const openTitle = (title: string): void => {
      state.title = title;
      state.showPopup = true;
    };

    const openFilter = () => {
      openUrl("https://jira.agoralab.co/issues/?jql=" + state.jql);
    };

    const computedData = ([vid, stage, priority, list]: DataWatchT) => {
      const stages = stageDic[stage as string];
      state.data = (list as Issue[]).filter((v) => {
        let flag = stages.includes(v.fields.status.name);
        if (priority !== priorityOpts[0].value) {
          flag = flag && v.fields.priority.name === priority;
        }
        if (vid !== vidOpts[0].value) {
          flag = flag && v.fields.customfield_12600.trim() === vid;
        }
        return flag;
      });

      let jql = `project = CSD AND status in (${stages
        .map((v) => `'${v === "In Progress" ? "InProgress" : v}'`)
        .join(",")})`;

      if (vid !== vidOpts[0].value) {
        jql = jql + ` AND VID ~ ${vid}`;
      } else {
        jql = jql + ` AND CompanyID ~ ${store.state.CID}`;
      }

      if (priority !== priorityOpts[0].value) {
        jql = jql + ` AND priority = ${priority}`;
      }
      state.jql = jql + ` ORDER BY created DESC`;
    };

    watch(
      (): DataWatchT => [
        state.vid,
        state.stage,
        state.priority,
        store.state.jira.list,
      ],
      computedData
    );

    const closePopup = () => {
      state.title = "";
    };

    const onRefresh = async () => {
      state.refreshing = true;
      await store.dispatch("jira/fetchJiraList", store.state.CID);
      state.refreshing = false;
    };

    onMounted(async () => {
      computedData([
        state.vid,
        state.stage,
        state.priority,
        store.state.jira.list,
      ]);
    });

    return {
      state,
      openTitle,
      onRefresh,
      onVidChange,
      openJira,
      openUrl,
      openFilter,
      closePopup,
      priorityOpts,
      stageOpts,
      priorityIconMap,
    };
  },
});
</script>

<style lang="less" scoped>
@import "../../style/app.less";
@headerHeight: 54px;
@menuHeight: 48px;

.jira_page {
  padding-top: @headerHeight+ @menuHeight;
  .fixed {
    position: fixed;
    top: @navHeight;
    background-color: #ffffff;
    width: 100%;
    > .header {
      display: flex;
      padding: 0 15px;
      align-items: center;
      justify-content: space-between;
      font-size: 13px;
      z-index: 3;
      width: 100%;
      height: @headerHeight;
      div.left {
        cursor: pointer;
        color: #ffffff;
        background: #368afa;
        padding: 5px 10px;
        border-radius: 10px;
      }
      div.right {
        display: flex;
        justify-content: flex-end;
        .btn {
          padding: 0px 5px;
          margin-left: 10px;
          // height: auto;
        }
      }
    }
    :deep(.van-dropdown-menu__title),
    :deep(.van-cell__title) {
      font-size: 13px;
      flex: 10;
    }
    :deep(.van-dropdown-menu) {
      width: 100%;
      z-index: 2;
    }
    :deep(.van-cell__left-icon) {
      display: flex;
      align-items: center;
    }
  }

  .jira_list {
    font-size: 12px;
    padding: 4px 0;
    li {
      display: flex;
      padding: 10px;
      justify-content: space-between;
      border-bottom: 1px solid #00000017;
      .key {
        width: 80px;
        text-align: left;
        color: @blue;
        font-weight: bold;
      }
      .title {
        flex: 1;
        margin: 0 0 0 0;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .date {
        text-align: right;
      }
      .icon {
        height: 16px;
        margin: 0 10px 0 10px;
      }
    }
  }
  .popup_title {
    width: 70vw;
    font-size: 13px;
    padding: 15px;
    line-height: 2;
  }
}
</style>
