
import { useStore } from "@/store";
import Notify from "vant/lib/notify";
import { defineComponent, reactive } from "vue";

export default defineComponent({
  setup() {
    const store = useStore();
    const state = reactive({
      cid: "",
      submiting: false,
      showPicker: false,
    });
    const onSubmit = async (values: any) => {
      if (!values.cid) {
        Notify({
          type: "danger",
          message: "无效CID",
        });
        return;
      }

      state.submiting = true;

      store.commit("setCID", values.cid);

      if (!store.state.chatID) {
        Notify({
          type: "warning",
          message: "未获取到当前群ID,暂时无法将CID与本群建立关联关系",
        });
      } else {
        store.dispatch("updateChatGroupInfo", {
          chatId: store.state.chatID,
          cid: values.cid,
        });
      }
      await store.dispatch("fetchCustomerArcherInfo", values.cid);
      await store.commit("jira/setLoading", true);
      await store.dispatch("jira/fetchJiraList", values.cid);
      await store.commit("jira/setLoading", false);

      state.submiting = false;
    };
    return {
      state,
      onSubmit,
    };
  },
});
