<template>
  <div class="customer_page">
    <van-empty
      v-if="!state.showContent"
      description="该CID无相关VID，请确认客户是否有创建项目"
    />
    <div v-else>
      <div class="header">
        客户360 VID 表链接
        <van-button
          plain
          icon="share"
          type="primary"
          size="mini"
          @click="jumpToArcher"
        />
      </div>
      <div class="vid_title">
        VID : {{ state.vid }}
        <van-button
          plain
          icon="exchange"
          type="primary"
          size="mini"
          @click="openPopup"
        />
      </div>
      <van-loading
        type="spinner"
        size="40"
        v-if="state.loading"
        style="margin-top: 40px"
      />
      <info :data="state.projectInfo || []" title="项目表关键信息" v-else />
    </div>
    <van-popup
      :show="state.popupShow"
      @click-overlay="closePopup"
      position="right"
    >
      <ul class="common_popup_ul" style="width: 80vw">
        <template v-for="(v, i) in state.vidOptions">
          <li
            v-if="v.value === state.vid"
            :key="v.value"
            class="active"
            @click="selectVid(v.value)"
          >
            {{ v.text }}
          </li>
          <li v-else :key="v.value + i" @click="selectVid(v.value)">
            {{ v.text }}
          </li>
        </template>
      </ul>
    </van-popup>
  </div>
</template>
<script lang="ts">
import { useStore } from "@/store";
import { openUrl } from "@/utils";
import { computed, defineComponent, reactive } from "vue";
import { translationArcherVendorModel } from "../model";
import Info from "./../Info.vue";
export default defineComponent({
  name: "customer",
  components: {
    Info,
  },
  setup() {
    const store = useStore();
    const state = reactive({
      popupShow: false,
      loading: false,
      showContent: computed(() => {
        return store.state.projectInfo && store.state.projectInfo.length > 0;
      }),
      vid: computed(() => store.state.VID),
      vidOptions: computed(() => store.state.vidOptions),
      projectInfo: computed(() => {
        const pInfos = store.state.projectInfo.filter((v) => {
          return String(v.vendor_id) === store.state.VID;
        });
        if (pInfos[0]) {
          return translationArcherVendorModel(
            pInfos[0],
            [
              "vid_nickname",
              "vid.csdc_vender_sdk_usage.use_case",
              "vid.csdc_vendor_product_types.uap_configured_product_types",
              "vid.csdc_vendor_product_types.used_inweek_product_types",
              "vid.csdc_vendor_product_types.rtm_used_or_not",
              "vid.csdc_vendor_product_types.ncs_configured_types",
              "vid.csdc_vendor_product_types.ad_products_used_inweek_app_types",
              "function_unit",
              "uid",
              "company_id.csdc_company.company_tam",
              "company_id.csdc_company.company_sa",
              "company_id.csdc_company.company_cse",
              "project_comments",
            ],
            "vendor"
          );
        }
        return [];
      }),
    });

    const openPopup = () => {
      state.popupShow = true;
    };

    const closePopup = () => {
      state.popupShow = false;
    };

    const jumpToArcher = () => {
      openUrl(
        "https://archer.agoralab.co/v2/data-app/csdc_vendor?csdc_vendor.vid=" +
          state.vid +
          "&pageNumber=1&pageSize=10&sortKey=&sortDirection=descending&author=&keywords=&vid.agora_vid_usage_cube_wow_v.current_week_usage=&vid.agora_vid_usage_cube_wow_v.current_week_over_week=&vid.csdc_vender_sdk_usage.use_case=&vid.csdc_vendor_jira_list.csd_bug_unresolved_list=&vid.csdc_vendor_jira_list.csd_bug_unresolved_amount=&vid.csdc_vendor_jira_list.csd_newfeature_unresolved_list=&vid.csdc_vendor_jira_list.csd_newfeature_unresolved_amount=&vid.csdc_vendor_product_types.uap_configured_product_types=0&vid.csdc_vendor_product_types.used_inweek_product_types=0&vid.csdc_vendor_product_types.rtm_weekly_usage=&vid.csdc_vendor_product_types.rtm_used_or_not=0&vid.csdc_vendor_product_types.ncs_configured_types=0&vid.csdc_vendor_product_types.ad_products_used_inweek_app_types=0&csdc_vendor.vid_nickname=&csdc_vendor.app=&csdc_vendor.vendor_type=&csdc_vendor.project_stage=&csdc_vendor.vendor_id=&csdc_vendor.create_time=&csdc_vendor.vendor_industry=&csdc_vendor.company_id=&company_id.csdc_company.area=&company_id.wide_company.sales_email=&company_id.wide_company.company_last_month_mr_level=&company_id.wide_company.sp_company_owner_1_email=&company_id.wide_company.sp_company_backup_1_email=&company_id.wide_company.current_sp_support_level_str=&csdc_vendor.competitors=0&csdc_vendor.competitor_info=&csdc_vendor.os=0&csdc_vendor.stakeholders=&csdc_vendor.function_unit=0&csdc_vendor.uid=&csdc_vendor.tam=&csdc_vendor.sa=&csdc_vendor.cse=&csdc_vendor.strategy=&csdc_vendor.project_comments="
      );
    };

    const selectVid = (v: string) => {
      store.commit("setVID", v);
      closePopup();
    };

    return {
      closePopup,
      openPopup,
      jumpToArcher,
      selectVid,
      state,
    };
  },
});
</script>
<style lang="less" scoped>
@import "../index.less";
</style>
