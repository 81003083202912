<template>
  <van-form @submit="onSubmit" class="van-form">
    <van-field
      v-model="state.component"
      name="component"
      label="Component"
      readonly
      clickable
      placeholder=""
      @click="state.showComponentPicher = true"
      :rules="[{ required: true, message: '请选择Component' }]"
    />
    <van-field
      v-model="state.vid"
      name="vid"
      label="VID"
      readonly
      clickable
      placeholder=""
      @click="state.showVidPicher = true"
      :rules="[{ required: true, message: '请选择VID' }]"
    />
    <van-field
      name="time"
      label="工时/小时"
      :rules="[{ required: true, message: '请填写工时' }]"
    >
      <template #input>
        <van-stepper min="0" decimal-length="1" v-model="state.time" />
      </template>
    </van-field>
    <van-field
      name="count"
      label="Case数量"
      :rules="[{ required: true, message: '请填写Case数量' }]"
    >
      <template #input>
        <van-stepper min="0" decimal-length="0" integer v-model="state.count" />
      </template>
    </van-field>
    <van-field
      v-model="state.description"
      type="textarea"
      rows="3"
      name="description"
      label="问题描述"
      show-word-limit
      autosize
      maxlength="1000"
    />
    <div style="margin: 16px">
      <van-button
        round
        block
        type="primary"
        native-type="submit"
        :loading="state.submiting"
        loading-text="提交中..."
      >
        提交
      </van-button>
    </div>
  </van-form>

  <div class="total">
    <h4>
      统计周期:每周四 00:00 至 下周三 23:59
      <van-button
        icon="replay"
        round
        @click="fetchWorkHourJira"
        type="primary"
        :class="
          state.refreshing ? 'refreshBtn animation-rotating' : 'refreshBtn'
        "
        :disabled="state.refreshing"
        size="mini"
      />
    </h4>
    <p>
      1.本周总工时 :
      <span class="tag">{{ state.resolvedTime.toFixed(2) }}h</span>
    </p>
    <p>
      2.本周处理的Case数量 :
      <span class="tag">{{ state.resolvedCount }}</span>
    </p>
    <p>
      3.本周处理过的问题类型 :
      <van-tag
        v-for="item in state.resolvedComponents"
        type="danger"
        size="large"
        :key="item"
        >{{ item }}
      </van-tag>
    </p>
    <div class="operation">
      <div class="link" @click="openFilter">
        <van-icon name="share-o" /> 跳转客户 Jira Filter Dashboard
      </div>
    </div>
  </div>
  <van-popup v-model:show="state.showComponentPicher" position="right">
    <ul class="common_popup_ul">
      <li
        v-for="item in componentOpts"
        :key="item"
        :class="item === state.component && 'active'"
        @click="
          () => {
            onComponentConfirm(item);
          }
        "
      >
        {{ item }}
      </li>
    </ul>
  </van-popup>
  <van-popup v-model:show="state.showVidPicher" position="right">
    <ul class="common_popup_ul" style="width: 80vw">
      <li
        v-for="item in state.vidOptions"
        :key="item.value"
        :class="item.value === state.vid && 'active'"
        @click="
          () => {
            onVidConfirm(item.value);
          }
        "
      >
        {{ item.text }}
      </li>
    </ul>
  </van-popup>
</template>
<script lang="ts">
import { useStore } from "@/store";
import { openUrl } from "@/utils";
import dayjs from "dayjs";
import Notify from "vant/lib/notify";
import { computed, defineComponent, onMounted, reactive } from "vue";
import { fetchWeComWorkHourJira, weComWorkHour } from "./service";

const componentOpts = [
  "SDK",
  "音频",
  "视频",
  "WEB",
  "AD",
  "云平台",
  "集成",
  "沟通",
  "其他",
];
export default defineComponent({
  name: "work",
  setup() {
    const store = useStore();
    const state = reactive({
      refreshing: false,
      submiting: false,
      component: componentOpts[0],
      count: "1",
      vid: store.state.VID,
      time: "0",
      description: "",
      showComponentPicher: false,
      showVidPicher: false,
      vidOptions: computed(() => store.state.vidOptions),
      resolvedCount: 0,
      resolvedTime: 0,
      resolvedComponents: new Set(),
    });

    const fetchWorkHourJira = () => {
      state.refreshing = true;
      fetchWeComWorkHourJira(store.state.CID)
        .then((data) => {
          let startDay = dayjs();
          startDay =
            startDay.day() > 3 || startDay.day() === 0
              ? startDay
              : startDay.subtract(7, "day");
          startDay = startDay.day(4).startOf("day");

          let count = 0,
            time = 0;
          let components = new Set();
          data.issues &&
            data.issues.forEach((v) => {
              if (dayjs(v.fields.created) > startDay) {
                count = count + v.fields.customfield_14601;
                time = time + Number(v.fields.customfield_14602);
                v.fields.components.forEach((c) => components.add(c.name));
              }
            });
          state.resolvedCount = count;
          state.resolvedTime = time;
          state.resolvedComponents = components;
        })
        .finally(() => {
          state.refreshing = false;
        });
    };

    const onSubmit = (values: any) => {
      const { count, ...param } = values;
      state.submiting = true;
      weComWorkHour({
        count: Number(count),
        ...param,
      })
        .then(async () => {
          Notify({ type: "success", message: "提交成功" });
          state.count = "1";
          state.time = "0";
          state.description = "";
          // 更新工时
          fetchWorkHourJira();
        })
        .catch(() => {
          Notify({ type: "danger", message: "提交失败请稍后重试" });
        })
        .finally(() => {
          state.submiting = false;
        });
    };

    const onComponentConfirm = (value: string) => {
      state.component = value;
      state.showComponentPicher = false;
    };

    const onVidConfirm = (value: string) => {
      state.vid = value;
      state.showVidPicher = false;
    };

    const openFilter = () => {
      openUrl(
        `https://jira.agoralab.co/issues/?jql=project = TSD AND CompanyID ~ ${store.state.CID} ORDER BY created DESC`
      );
    };

    onMounted(() => {
      fetchWorkHourJira();
    });

    return {
      state,
      componentOpts,
      onSubmit,
      onComponentConfirm,
      onVidConfirm,
      fetchWorkHourJira,
      openFilter,
    };
  },
});
</script>

<style lang="less" scoped>
@import "../../style/common.less";
.operation {
  margin-top: 15px;
  display: flex;
  align-items: center;
  div.link {
    cursor: pointer;
    color: #ffffff;
    background: #368afa;
    padding: 6px 10px;
    border-radius: 10px;
    display: inline-block;
    margin-right: 10px;
    font-size: 12px;
  }
}

.van-form {
  .van-cell:nth-child(1),
  .van-cell:nth-child(2),
  .van-cell:nth-child(3),
  .van-cell:nth-child(4) {
    font-size: 13px;
    color: #4e4f4f;
    :deep(.van-field__label) {
      > span {
        &::after {
          content: "*";
          color: #ff0000;
          display: inline-block;
          margin-left: 2px;
        }
      }
    }
  }
  .van-cell:nth-child(1),
  .van-cell:nth-child(2) {
    :deep(.van-field__value) {
      &::after {
        content: "";
        display: inline-block;
        margin-left: 2px;
        position: absolute;
        top: 10px;
        right: 10px;
        width: 0px;
        height: 0px;
        border: 5px solid;
        border-color: #cecece transparent transparent;
      }
    }
  }
  .van-cell::after {
    border-bottom: 1px solid #dcdcdc;
  }
}

.total {
  text-align: left;
  padding: 10px 5%;
  h4 {
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  p {
    line-height: 2;
    margin: 5px 0;
    font-size: 14px;
  }
  p > span.tag {
    display: inline-block;
    color: @blue;

    font-weight: bold;
  }
  p > span {
    margin: 0 3px;
  }
}
</style>
