<template>
  <div class="customer_info_table">
    <div class="customer_info_table_header">{{ title }}</div>
    <p v-for="item in data" :key="item.name">
      <span class="name">{{ item.name }}</span> :
      <span v-if="Array.isArray(item.value)">
        <van-tag class="enumTag" v-for="v in item.value" :key="v">{{
          v
        }}</van-tag>
      </span>
      <span v-else class="text">{{ item.value }}</span>
    </p>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "CustomerCompanyInfo",
  props: {
    data: {
      type: Object,
    },
    title: {
      type: String,
      default: "",
    },
  },
});
</script>

<style lang="less" scoped>
@import "../../style/common.less";
.customer_info_table {
  font-size: 13px;
  text-align: left;
  margin-top: 10px;
  border: 1px solid #00000057;
  border-radius: 10px;

  .customer_info_table_header,
  & > p {
    line-height: 1.6;
    padding: 9px 12px;
    overflow-wrap: break-word;
    .name {
      font-weight: bold;
    }
    .enumTag {
      margin: 2px 5px 2px 0;
      background-color: #fceeeb;
      border: 1px solid #faded6;
      color: #e55934;
      font-size: 12px;
      padding: 4px 5px 3px;
      line-height: 1;
    }
    .text {
      white-space: pre-wrap;
    }
  }
  .customer_info_table_header {
    font-weight: bold;
    background: #0707070d;
  }
  & > p {
    margin: 0;
    border-top: 1px solid #00000057;
  }
  .customer_info_table_Item {
    padding: 10px 10px;
    background: #fff;
  }
}
</style>
