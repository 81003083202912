// 1. csdc_company
//    1. wide_company
//    2. csdc_company_jira_list
//    3. agora_cid_usage_cube_week_v
//    4. csdc_company_product_types
//    5. csdc_company_sdk_usage
// 2. csdc_vendor
//    1. csdc_company
//    2. wide_company
//    3. agora_vid_usage_cube_wow_v
//    4. csdc_vender_sdk_usage
//    5. csdc_vendor_jira_list
//    6. csdc_vendor_product_types

import { ArcherDataModel, CustomerInfoValue } from './service';

// 原数据
export const csdc_company: ArcherDataModel[] = [
  {
    modelKey: 'csdc_company',
    fieldKey: 'customer',
    name: '客户简称',
    fieldType: 'SingleLineText',
    createTime: '2021-04-23T02:40:35+00:00',
    updateTime: '2021-08-19T09:44:13+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_company',
    fieldKey: 'author',
    name: '创建者邮箱',
    fieldType: 'User',
    createTime: '2021-04-22T11:37:12+00:00',
    updateTime: '2021-04-22T11:37:12+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_company',
    fieldKey: 'update_author',
    name: '更新者邮箱',
    fieldType: 'User',
    createTime: '2021-04-22T11:37:12+00:00',
    updateTime: '2021-09-15T12:57:51+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_company',
    fieldKey: 'create_time',
    name: '创建时间',
    fieldType: 'Datetime',
    createTime: '2021-04-22T11:37:12+00:00',
    updateTime: '2021-05-13T04:13:01+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_company',
    fieldKey: 'update_time',
    name: '更新时间',
    fieldType: 'Datetime',
    createTime: '2021-04-22T11:37:12+00:00',
    updateTime: '2021-09-15T12:57:46+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_company',
    fieldKey: 'company_id',
    name: 'cid',
    fieldType: 'SingleLineText',
    createTime: '2021-04-23T02:38:06+00:00',
    updateTime: '2021-04-25T05:19:18+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_company',
    fieldKey: 'first_sp_level',
    name: '首次申请等级',
    fieldType: 'TextEnum',
    createTime: '2021-04-23T02:42:17+00:00',
    updateTime: '2021-04-25T12:04:46+00:00',
    options: [
      {
        label: 'S',
        value: '6',
        restraintValueMap: {},
      },
      {
        label: 'T0',
        value: '5',
        restraintValueMap: {},
      },
      {
        label: 'T1+',
        value: '4',
        restraintValueMap: {},
      },
      {
        label: 'T1',
        value: '3',
        restraintValueMap: {},
      },
      {
        label: 'T2',
        value: '2',
        restraintValueMap: {},
      },
      {
        label: 'T3',
        value: '1',
        restraintValueMap: {},
      },
      {
        label: '待补充',
        value: '7',
        restraintValueMap: {},
      },
    ],
    value2LabelMap: {
      '1': 'T3',
      '2': 'T2',
      '3': 'T1',
      '4': 'T1+',
      '5': 'T0',
      '6': 'S',
      '7': '待补充',
    },
  },
  {
    modelKey: 'csdc_company',
    fieldKey: 'area',
    name: '区域',
    fieldType: 'TextEnum',
    createTime: '2021-04-23T07:58:31+00:00',
    updateTime: '2021-06-24T10:25:32+00:00',
    options: [
      {
        label: '华东',
        value: '1',
        restraintValueMap: {},
      },
      {
        label: '华北',
        value: '2',
        restraintValueMap: {},
      },
      {
        label: '华南',
        value: '3',
        restraintValueMap: {},
      },
      {
        label: '华中',
        value: '4',
        restraintValueMap: {},
      },
    ],
    value2LabelMap: {
      '1': '华东',
      '2': '华北',
      '3': '华南',
      '4': '华中',
    },
  },
  {
    modelKey: 'csdc_company',
    fieldKey: 'customer_okr',
    name: '客户OKR',
    fieldType: 'MultipleLinesText',
    createTime: '2021-04-23T08:01:39+00:00',
    updateTime: '2021-04-23T08:01:39+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_company',
    fieldKey: 'stakeholders',
    name: '主要干系人及组织架构',
    fieldType: 'MultipleLinesText',
    createTime: '2021-04-23T08:04:58+00:00',
    updateTime: '2021-04-23T08:04:58+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_company',
    fieldKey: 'report',
    name: '周报',
    fieldType: 'TextEnum',
    createTime: '2021-04-23T08:07:28+00:00',
    updateTime: '2021-04-25T03:07:43+00:00',
    options: [
      {
        label: '是',
        value: '1',
        restraintValueMap: {},
      },
      {
        label: '否',
        value: '2',
        restraintValueMap: {},
      },
    ],
    value2LabelMap: {
      '1': '是',
      '2': '否',
    },
  },
  {
    modelKey: 'csdc_company',
    fieldKey: 'documents',
    name: '对外文档',
    fieldType: 'MultipleLinesText',
    createTime: '2021-04-23T08:09:05+00:00',
    updateTime: '2021-04-23T08:09:05+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_company',
    fieldKey: 'wechat_group',
    name: '主微信群',
    fieldType: 'MultipleLinesText',
    createTime: '2021-04-23T08:09:47+00:00',
    updateTime: '2021-04-23T08:09:47+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_company',
    fieldKey: 'activity',
    name: '4月活跃度',
    fieldType: 'TextEnum',
    createTime: '2021-04-23T08:15:15+00:00',
    updateTime: '2021-05-07T02:59:23+00:00',
    options: [
      {
        label: '无问题',
        value: '1',
        restraintValueMap: {},
      },
      {
        label: '< 5/周',
        value: '2',
        restraintValueMap: {},
      },
      {
        label: '< 15/周',
        value: '3',
        restraintValueMap: {},
      },
      {
        label: '< 25/周',
        value: '4',
        restraintValueMap: {},
      },
      {
        label: '< 40/周',
        value: '5',
        restraintValueMap: {},
      },
      {
        label: '>= 40/周',
        value: '6',
        restraintValueMap: {},
      },
    ],
    value2LabelMap: {
      '1': '无问题',
      '2': '< 5/周',
      '3': '< 15/周',
      '4': '< 25/周',
      '5': '< 40/周',
      '6': '>= 40/周',
    },
  },
  {
    modelKey: 'csdc_company',
    fieldKey: 'customer_type',
    name: '客户类型',
    fieldType: 'Tags',
    createTime: '2021-04-25T05:07:58+00:00',
    updateTime: '2021-07-30T10:41:35+00:00',
    options: [
      {
        label: '冲刺',
        value: 1,
        restraintValueMap: {},
      },
      {
        label: '公司重点Winback',
        value: 2,
        restraintValueMap: {},
      },
      {
        label: '区域重点Winback',
        value: 3,
        restraintValueMap: {},
      },
      {
        label: '一般Winback',
        value: 4,
        restraintValueMap: {},
      },
      {
        label: '高影响力',
        value: 5,
        restraintValueMap: {},
      },
      {
        label: 'aPaaS',
        value: 6,
        restraintValueMap: {},
      },
      {
        label: '3.1.100.2推广目标',
        value: 7,
        restraintValueMap: {},
      },
      {
        label: '重点防守',
        value: 8,
        restraintValueMap: {},
      },
    ],
    value2LabelMap: {
      '1': '冲刺',
      '2': '公司重点Winback',
      '3': '区域重点Winback',
      '4': '一般Winback',
      '5': '高影响力',
      '6': 'aPaaS',
      '7': '3.1.100.2推广目标',
      '8': '重点防守',
    },
  },
  {
    modelKey: 'csdc_company',
    fieldKey: 'epic',
    name: 'epic号',
    fieldType: 'SingleLineText',
    createTime: '2021-04-25T05:08:24+00:00',
    updateTime: '2021-04-25T05:08:24+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_company',
    fieldKey: 'tags',
    name: '业务标签',
    fieldType: 'Tags',
    createTime: '2021-04-25T05:08:54+00:00',
    updateTime: '2021-04-25T05:08:54+00:00',
    options: [
      {
        label: '赛道TOP3 blabla',
        value: 1,
        restraintValueMap: {},
      },
    ],
    value2LabelMap: {
      '1': '赛道TOP3 blabla',
    },
  },
  {
    modelKey: 'csdc_company',
    fieldKey: 'visit',
    name: '客户拜访',
    fieldType: 'MultipleLinesText',
    createTime: '2021-04-25T05:09:14+00:00',
    updateTime: '2021-04-25T05:10:33+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_company',
    fieldKey: 'important',
    name: '重要等级',
    fieldType: 'TextEnum',
    createTime: '2021-04-25T07:20:03+00:00',
    updateTime: '2021-04-25T07:20:03+00:00',
    options: [
      {
        label: '重要',
        value: '1',
        restraintValueMap: {},
      },
      {
        label: '关注',
        value: '2',
        restraintValueMap: {},
      },
      {
        label: '一般',
        value: '3',
        restraintValueMap: {},
      },
    ],
    value2LabelMap: {
      '1': '重要',
      '2': '关注',
      '3': '一般',
    },
  },
  {
    modelKey: 'csdc_company',
    fieldKey: 'customer_remark',
    name: '客户备注',
    fieldType: 'MultipleLinesText',
    createTime: '2021-04-25T09:26:09+00:00',
    updateTime: '2021-04-25T09:26:09+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_company',
    fieldKey: 'competitors',
    name: '竞品',
    fieldType: 'Tags',
    createTime: '2021-04-27T02:46:36+00:00',
    updateTime: '2021-04-27T02:46:36+00:00',
    options: [
      {
        label: '不详',
        value: 1,
        restraintValueMap: {},
      },
      {
        label: '无',
        value: 2,
        restraintValueMap: {},
      },
      {
        label: '其他',
        value: 3,
        restraintValueMap: {},
      },
      {
        label: '自研',
        value: 4,
        restraintValueMap: {},
      },
      {
        label: 'Zego',
        value: 5,
        restraintValueMap: {},
      },
      {
        label: '好未来直播云',
        value: 6,
        restraintValueMap: {},
      },
      {
        label: '腾讯',
        value: 7,
        restraintValueMap: {},
      },
      {
        label: '阿里',
        value: 8,
        restraintValueMap: {},
      },
      {
        label: '网易',
        value: 9,
        restraintValueMap: {},
      },
      {
        label: '拓客云',
        value: 10,
        restraintValueMap: {},
      },
      {
        label: 'ClassIn',
        value: 11,
        restraintValueMap: {},
      },
      {
        label: 'JustTalk',
        value: 12,
        restraintValueMap: {},
      },
      {
        label: 'Zoom',
        value: 13,
        restraintValueMap: {},
      },
      {
        label: '七牛',
        value: 14,
        restraintValueMap: {},
      },
      {
        label: '清流鼎点',
        value: 15,
        restraintValueMap: {},
      },
      {
        label: '三体云',
        value: 16,
        restraintValueMap: {},
      },
      {
        label: '环信',
        value: 17,
        restraintValueMap: {},
      },
      {
        label: 'YY',
        value: 18,
        restraintValueMap: {},
      },
      {
        label: '融云',
        value: 19,
        restraintValueMap: {},
      },
    ],
    value2LabelMap: {
      '1': '不详',
      '2': '无',
      '3': '其他',
      '4': '自研',
      '5': 'Zego',
      '6': '好未来直播云',
      '7': '腾讯',
      '8': '阿里',
      '9': '网易',
      '10': '拓客云',
      '11': 'ClassIn',
      '12': 'JustTalk',
      '13': 'Zoom',
      '14': '七牛',
      '15': '清流鼎点',
      '16': '三体云',
      '17': '环信',
      '18': 'YY',
      '19': '融云',
    },
  },
  {
    modelKey: 'csdc_company',
    fieldKey: 'competitor_info',
    name: '竞品信息',
    fieldType: 'MultipleLinesText',
    createTime: '2021-04-27T02:46:50+00:00',
    updateTime: '2021-04-27T02:46:50+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_company',
    fieldKey: 'oncall_info',
    name: '值班须知',
    fieldType: 'MultipleLinesText',
    createTime: '2021-06-17T07:01:22+00:00',
    updateTime: '2021-06-17T07:01:22+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_company',
    fieldKey: 'rid',
    name: '序号',
    fieldType: 'Integer',
    createTime: '2021-08-28T08:14:02+00:00',
    updateTime: '2021-08-28T08:14:02+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_company',
    fieldKey: 'company_tam',
    name: 'TAM',
    fieldType: 'User',
    createTime: '2021-09-08T03:15:35+00:00',
    updateTime: '2021-09-08T03:15:35+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_company',
    fieldKey: 'company_sa',
    name: 'SA',
    fieldType: 'User',
    createTime: '2021-09-08T03:15:57+00:00',
    updateTime: '2021-09-08T03:15:57+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_company',
    fieldKey: 'company_cse',
    name: 'CSE',
    fieldType: 'User',
    createTime: '2021-09-08T03:16:18+00:00',
    updateTime: '2021-09-08T03:16:18+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_company',
    fieldKey: 'cse_backup1',
    name: 'CSE backup1',
    fieldType: 'User',
    createTime: '2021-09-29T02:48:19+00:00',
    updateTime: '2021-09-29T02:48:19+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_company',
    fieldKey: 'cse_backup2',
    name: 'CSE  backup2',
    fieldType: 'User',
    createTime: '2021-09-29T02:51:46+00:00',
    updateTime: '2021-09-29T02:51:46+00:00',
    options: [],
    value2LabelMap: {},
  },
];
export const csdc_company_jira_list: ArcherDataModel[] = [
  {
    modelKey: 'csdc_company_jira_list',
    fieldKey: '__rid',
    name: '自增 ID',
    fieldType: 'Integer',
    createTime: '2021-06-09T03:31:18+00:00',
    updateTime: '2021-06-09T03:31:18+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_company_jira_list',
    fieldKey: 'author',
    name: '创建者邮箱',
    fieldType: 'User',
    createTime: '2021-06-09T03:31:18+00:00',
    updateTime: '2021-06-09T03:31:18+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_company_jira_list',
    fieldKey: 'update_author',
    name: '更新者邮箱',
    fieldType: 'User',
    createTime: '2021-06-09T03:31:18+00:00',
    updateTime: '2021-06-09T03:31:18+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_company_jira_list',
    fieldKey: 'create_time',
    name: '创建时间',
    fieldType: 'Datetime',
    createTime: '2021-06-09T03:31:18+00:00',
    updateTime: '2021-06-09T03:31:18+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_company_jira_list',
    fieldKey: 'update_time',
    name: '更新时间',
    fieldType: 'Datetime',
    createTime: '2021-06-09T03:31:18+00:00',
    updateTime: '2021-06-09T03:31:18+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_company_jira_list',
    fieldKey: 'company_id',
    name: 'company_id',
    fieldType: 'Integer',
    createTime: '2021-06-09T03:33:28+00:00',
    updateTime: '2021-06-09T03:33:28+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_company_jira_list',
    fieldKey: 'csd_bug_unresolved_list',
    name: 'csd_bug_unresolved_list',
    fieldType: 'MultipleLinesText',
    createTime: '2021-06-09T03:33:50+00:00',
    updateTime: '2021-06-09T03:33:50+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_company_jira_list',
    fieldKey: 'csd_bug_unresolved_amount',
    name: 'csd_bug_unresolved_amount',
    fieldType: 'Integer',
    createTime: '2021-06-09T03:33:59+00:00',
    updateTime: '2021-06-09T03:33:59+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_company_jira_list',
    fieldKey: 'csd_newfeature_unresolved_list',
    name: 'csd_newfeature_unresolved_list',
    fieldType: 'MultipleLinesText',
    createTime: '2021-06-09T03:34:11+00:00',
    updateTime: '2021-06-09T03:34:11+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_company_jira_list',
    fieldKey: 'csd_newfeature_unresolved_amount',
    name: 'csd_newfeature_unresolved_amount',
    fieldType: 'Integer',
    createTime: '2021-06-09T03:34:18+00:00',
    updateTime: '2021-06-09T03:34:18+00:00',
    options: [],
    value2LabelMap: {},
  },
];

export const csdc_company_product_types: ArcherDataModel[] = [
  {
    modelKey: 'csdc_company_product_types',
    fieldKey: '__rid',
    name: '自增 ID',
    fieldType: 'Integer',
    createTime: '2021-02-22T06:19:44+00:00',
    updateTime: '2021-02-22T06:19:44+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_company_product_types',
    fieldKey: 'author',
    name: '创建者邮箱',
    fieldType: 'User',
    createTime: '2021-02-22T06:19:44+00:00',
    updateTime: '2021-02-22T06:19:44+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_company_product_types',
    fieldKey: 'update_author',
    name: '更新者邮箱',
    fieldType: 'User',
    createTime: '2021-02-22T06:19:44+00:00',
    updateTime: '2021-02-22T06:19:44+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_company_product_types',
    fieldKey: 'create_time',
    name: '创建时间',
    fieldType: 'Datetime',
    createTime: '2021-02-22T06:19:44+00:00',
    updateTime: '2021-02-22T06:19:44+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_company_product_types',
    fieldKey: 'update_time',
    name: '更新时间',
    fieldType: 'Datetime',
    createTime: '2021-02-22T06:19:44+00:00',
    updateTime: '2021-02-22T06:19:44+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_company_product_types',
    fieldKey: 'uap_configured_product_types',
    name: '开通云服务',
    fieldType: 'Tags',
    createTime: '2021-02-22T06:20:44+00:00',
    updateTime: '2021-02-22T06:25:47+00:00',
    options: [
      {
        label: 'Mini Program',
        value: 1,
        restraintValueMap: {},
      },
      {
        label: 'Server Streaming',
        value: 2,
        restraintValueMap: {},
      },
      {
        label: 'Push Streaming2.0',
        value: 3,
        restraintValueMap: {},
      },
      {
        label: 'Cloud Proxy',
        value: 4,
        restraintValueMap: {},
      },
      {
        label: 'WebRTC Proxy',
        value: 5,
        restraintValueMap: {},
      },
      {
        label: 'Cross Channel Host-in',
        value: 6,
        restraintValueMap: {},
      },
      {
        label: 'Cloud Recording',
        value: 7,
        restraintValueMap: {},
      },
      {
        label: 'Push Streaming3.0',
        value: 8,
        restraintValueMap: {},
      },
      {
        label: 'Cloud Player',
        value: 9,
        restraintValueMap: {},
      },
      {
        label: 'RTMP Converter',
        value: 10,
        restraintValueMap: {},
      },
      {
        label: 'RTMP Pusher',
        value: 11,
        restraintValueMap: {},
      },
      {
        label: 'Cloud Simulcast',
        value: 12,
        restraintValueMap: {},
      },
      {
        label: 'Image Moderation API',
        value: 13,
        restraintValueMap: {},
      },
      {
        label: 'Cloud Transcoder',
        value: 14,
        restraintValueMap: {},
      },
    ],
    value2LabelMap: {
      '1': 'Mini Program',
      '2': 'Server Streaming',
      '3': 'Push Streaming2.0',
      '4': 'Cloud Proxy',
      '5': 'WebRTC Proxy',
      '6': 'Cross Channel Host-in',
      '7': 'Cloud Recording',
      '8': 'Push Streaming3.0',
      '9': 'Cloud Player',
      '10': 'RTMP Converter',
      '11': 'RTMP Pusher',
      '12': 'Cloud Simulcast',
      '13': 'Image Moderation API',
      '14': 'Cloud Transcoder',
    },
  },
  {
    modelKey: 'csdc_company_product_types',
    fieldKey: 'company_id',
    name: 'company_id',
    fieldType: 'Integer',
    createTime: '2021-02-22T06:20:59+00:00',
    updateTime: '2021-02-22T06:20:59+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_company_product_types',
    fieldKey: 'used_inweek_product_types',
    name: '有用量云服务',
    fieldType: 'Tags',
    createTime: '2021-02-22T06:23:05+00:00',
    updateTime: '2021-02-22T06:28:20+00:00',
    options: [
      {
        label: 'Native',
        value: 1,
        restraintValueMap: {},
      },
      {
        label: 'PSTN',
        value: 2,
        restraintValueMap: {},
      },
      {
        label: 'OnPremiseRecording',
        value: 3,
        restraintValueMap: {},
      },
      {
        label: 'Mosaic',
        value: 4,
        restraintValueMap: {},
      },
      {
        label: 'WebRTS',
        value: 5,
        restraintValueMap: {},
      },
      {
        label: 'LbPublish',
        value: 6,
        restraintValueMap: {},
      },
      {
        label: 'LbInject',
        value: 7,
        restraintValueMap: {},
      },
      {
        label: 'MiniApp',
        value: 8,
        restraintValueMap: {},
      },
      {
        label: 'ServerStreaming',
        value: 9,
        restraintValueMap: {},
      },
      {
        label: 'CloudRecording',
        value: 10,
        restraintValueMap: {},
      },
      {
        label: 'MixStreaming',
        value: 11,
        restraintValueMap: {},
      },
      {
        label: 'RawStreaming',
        value: 12,
        restraintValueMap: {},
      },
      {
        label: 'InjectStreaming',
        value: 13,
        restraintValueMap: {},
      },
      {
        label: 'Transmission',
        value: 14,
        restraintValueMap: {},
      },
      {
        label: 'MediaServer',
        value: 15,
        restraintValueMap: {},
      },
      {
        label: 'AudioModeration',
        value: 16,
        restraintValueMap: {},
      },
      {
        label: 'WebRTC',
        value: 17,
        restraintValueMap: {},
      },
      {
        label: 'Flv',
        value: 21,
        restraintValueMap: {},
      },
      {
        label: 'RTSA',
        value: 22,
        restraintValueMap: {},
      },
      {
        label: 'LocalProxy',
        value: 23,
        restraintValueMap: {},
      },
      {
        label: 'ProxyVos',
        value: 24,
        restraintValueMap: {},
      },
      {
        label: 'WebRTCOpenPlatform',
        value: 25,
        restraintValueMap: {},
      },
      {
        label: 'Snapshot',
        value: 26,
        restraintValueMap: {},
      },
      {
        label: 'VideoModeration',
        value: 27,
        restraintValueMap: {},
      },
      {
        label: 'CloudPlayer',
        value: 28,
        restraintValueMap: {},
      },
      {
        label: 'RTMPConverter',
        value: 30,
        restraintValueMap: {},
      },
      {
        label: 'ServerSDKSend',
        value: 31,
        restraintValueMap: {},
      },
      {
        label: 'ServerSDKReceive',
        value: 32,
        restraintValueMap: {},
      },
      {
        label: 'ServerSDKGate',
        value: 33,
        restraintValueMap: {},
      },
      {
        label: 'RTMPPusher',
        value: 34,
        restraintValueMap: {},
      },
    ],
    value2LabelMap: {
      '1': 'Native',
      '2': 'PSTN',
      '3': 'OnPremiseRecording',
      '4': 'Mosaic',
      '5': 'WebRTS',
      '6': 'LbPublish',
      '7': 'LbInject',
      '8': 'MiniApp',
      '9': 'ServerStreaming',
      '10': 'CloudRecording',
      '11': 'MixStreaming',
      '12': 'RawStreaming',
      '13': 'InjectStreaming',
      '14': 'Transmission',
      '15': 'MediaServer',
      '16': 'AudioModeration',
      '17': 'WebRTC',
      '21': 'Flv',
      '22': 'RTSA',
      '23': 'LocalProxy',
      '24': 'ProxyVos',
      '25': 'WebRTCOpenPlatform',
      '26': 'Snapshot',
      '27': 'VideoModeration',
      '28': 'CloudPlayer',
      '30': 'RTMPConverter',
      '31': 'ServerSDKSend',
      '32': 'ServerSDKReceive',
      '33': 'ServerSDKGate',
      '34': 'RTMPPusher',
    },
  },
  {
    modelKey: 'csdc_company_product_types',
    fieldKey: 'ad_products_used_inweek_app_types',
    name: '有用量的第三方框架',
    fieldType: 'Tags',
    createTime: '2021-05-24T09:32:52+00:00',
    updateTime: '2021-05-24T09:32:52+00:00',
    options: [
      {
        label: 'flutter',
        value: 1,
        restraintValueMap: {},
      },
      {
        label: 'c#',
        value: 2,
        restraintValueMap: {},
      },
      {
        label: 'uniapp',
        value: 3,
        restraintValueMap: {},
      },
      {
        label: 'python',
        value: 4,
        restraintValueMap: {},
      },
      {
        label: 'unity',
        value: 5,
        restraintValueMap: {},
      },
      {
        label: 'react_native',
        value: 6,
        restraintValueMap: {},
      },
      {
        label: 'electron',
        value: 7,
        restraintValueMap: {},
      },
      {
        label: 'cocos_creator',
        value: 8,
        restraintValueMap: {},
      },
      {
        label: 'cef',
        value: 9,
        restraintValueMap: {},
      },
      {
        label: 'cocos',
        value: 10,
        restraintValueMap: {},
      },
    ],
    value2LabelMap: {
      '1': 'flutter',
      '2': 'c#',
      '3': 'uniapp',
      '4': 'python',
      '5': 'unity',
      '6': 'react_native',
      '7': 'electron',
      '8': 'cocos_creator',
      '9': 'cef',
      '10': 'cocos',
    },
  },
  {
    modelKey: 'csdc_company_product_types',
    fieldKey: 'ncs_configured_types',
    name: '开通的NCS消息类型',
    fieldType: 'Tags',
    createTime: '2021-05-24T09:36:45+00:00',
    updateTime: '2021-05-24T09:36:45+00:00',
    options: [
      {
        label: 'Real-Time Communication',
        value: 1,
        restraintValueMap: {},
      },
      {
        label: 'RTMP Converter',
        value: 2,
        restraintValueMap: {},
      },
      {
        label: 'Push Stream to CDN 3.0',
        value: 3,
        restraintValueMap: {},
      },
      {
        label: 'Cloud Simulcast',
        value: 4,
        restraintValueMap: {},
      },
      {
        label: 'Cloud Recording',
        value: 5,
        restraintValueMap: {},
      },
      {
        label: 'Cloud Player',
        value: 6,
        restraintValueMap: {},
      },
    ],
    value2LabelMap: {
      '1': 'Real-Time Communication',
      '2': 'RTMP Converter',
      '3': 'Push Stream to CDN 3.0',
      '4': 'Cloud Simulcast',
      '5': 'Cloud Recording',
      '6': 'Cloud Player',
    },
  },
  {
    modelKey: 'csdc_company_product_types',
    fieldKey: 'rtm_weekly_usage',
    name: 'RTM周用量',
    fieldType: 'Integer',
    createTime: '2021-05-24T09:37:31+00:00',
    updateTime: '2021-05-24T09:37:31+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_company_product_types',
    fieldKey: 'rtm_used_or_not',
    name: 'RTM是否有周用量',
    fieldType: 'Tags',
    createTime: '2021-05-28T06:18:25+00:00',
    updateTime: '2021-05-28T06:18:25+00:00',
    options: [
      {
        label: 'YES',
        value: 1,
        restraintValueMap: {},
      },
      {
        label: 'NO',
        value: 2,
        restraintValueMap: {},
      },
    ],
    value2LabelMap: {
      '1': 'YES',
      '2': 'NO',
    },
  },
];

export const csdc_vendor: ArcherDataModel[] = [
  {
    modelKey: 'csdc_vendor',
    fieldKey: 'vid',
    name: 'vid',
    fieldType: 'SingleLineText',
    createTime: '2021-04-23T08:29:12+00:00',
    updateTime: '2021-06-25T06:19:22+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_vendor',
    fieldKey: 'vid_nickname',
    name: '简称',
    fieldType: 'SingleLineText',
    createTime: '2021-04-23T08:39:40+00:00',
    updateTime: '2021-06-25T06:19:19+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_vendor',
    fieldKey: 'app',
    name: 'APP名称',
    fieldType: 'SingleLineText',
    createTime: '2021-04-23T09:27:05+00:00',
    updateTime: '2021-06-25T06:19:15+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_vendor',
    fieldKey: 'vendor_type',
    name: '类型',
    fieldType: 'TextEnum',
    createTime: '2021-04-23T08:51:36+00:00',
    updateTime: '2021-06-25T06:19:08+00:00',
    options: [
      {
        label: '正式',
        value: '1',
        restraintValueMap: {},
      },
      {
        label: '测试',
        value: '2',
        restraintValueMap: {},
      },
      {
        label: '未定义',
        value: '3',
        restraintValueMap: {},
      },
      {
        label: '未编辑',
        value: '4',
        restraintValueMap: {},
      },
    ],
    value2LabelMap: {
      '1': '正式',
      '2': '测试',
      '3': '未定义',
      '4': '未编辑',
    },
  },
  {
    modelKey: 'csdc_vendor',
    fieldKey: 'project_stage',
    name: '阶段',
    fieldType: 'TextEnum',
    createTime: '2021-04-25T02:50:17+00:00',
    updateTime: '2021-06-25T06:19:04+00:00',
    options: [
      {
        label: '测试',
        value: '1',
        restraintValueMap: {},
      },
      {
        label: '集成',
        value: '2',
        restraintValueMap: {},
      },
      {
        label: '上线',
        value: '3',
        restraintValueMap: {},
      },
      {
        label: '上量',
        value: '4',
        restraintValueMap: {},
      },
      {
        label: '留存',
        value: '5',
        restraintValueMap: {},
      },
      {
        label: '衰退',
        value: '6',
        restraintValueMap: {},
      },
      {
        label: '流失',
        value: '7',
        restraintValueMap: {},
      },
    ],
    value2LabelMap: {
      '1': '测试',
      '2': '集成',
      '3': '上线',
      '4': '上量',
      '5': '留存',
      '6': '衰退',
      '7': '流失',
    },
  },
  {
    modelKey: 'csdc_vendor',
    fieldKey: 'vendor_id',
    name: '项目信息（填vid）',
    fieldType: 'VendorID',
    createTime: '2021-04-23T08:26:52+00:00',
    updateTime: '2021-06-25T06:19:02+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_vendor',
    fieldKey: 'create_time',
    name: '创建时间',
    fieldType: 'Datetime',
    createTime: '2021-04-23T08:26:05+00:00',
    updateTime: '2021-06-25T06:19:00+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_vendor',
    fieldKey: 'vendor_industry',
    name: '行业',
    fieldType: 'TextEnum',
    createTime: '2021-04-23T09:20:13+00:00',
    updateTime: '2021-06-25T06:18:57+00:00',
    options: [
      {
        label: '泛娱乐',
        value: '1',
        restraintValueMap: {},
      },
      {
        label: '教育',
        value: '2',
        restraintValueMap: {},
      },
      {
        label: 'NUC',
        value: '3',
        restraintValueMap: {},
      },
    ],
    value2LabelMap: {
      '1': '泛娱乐',
      '2': '教育',
      '3': 'NUC',
    },
  },
  {
    modelKey: 'csdc_vendor',
    fieldKey: 'company_id',
    name: 'cid',
    fieldType: 'SingleLineText',
    createTime: '2021-04-23T09:20:48+00:00',
    updateTime: '2021-06-25T06:18:54+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_vendor',
    fieldKey: 'vendor_tag',
    name: '项目标签',
    fieldType: 'Tags',
    createTime: '2021-06-25T06:18:28+00:00',
    updateTime: '2021-06-25T06:18:44+00:00',
    options: [
      {
        label: '标杆项目',
        value: 1,
        restraintValueMap: {},
      },
      {
        label: 'Winback',
        value: 2,
        restraintValueMap: {},
      },
      {
        label: 'aPaaS',
        value: 3,
        restraintValueMap: {},
      },
      {
        label: '大重构',
        value: 4,
        restraintValueMap: {},
      },
      {
        label: 'NASA2',
        value: 5,
        restraintValueMap: {},
      },
      {
        label: 'GO CDN',
        value: 6,
        restraintValueMap: {},
      },
    ],
    value2LabelMap: {
      '1': '标杆项目',
      '2': 'Winback',
      '3': 'aPaaS',
      '4': '大重构',
      '5': 'NASA2',
      '6': 'GO CDN',
    },
  },
  {
    modelKey: 'csdc_vendor',
    fieldKey: 'author',
    name: '创建者邮箱',
    fieldType: 'User',
    createTime: '2021-04-23T08:26:05+00:00',
    updateTime: '2021-04-23T08:26:05+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_vendor',
    fieldKey: 'update_author',
    name: '更新者邮箱',
    fieldType: 'User',
    createTime: '2021-04-23T08:26:05+00:00',
    updateTime: '2021-04-23T08:26:05+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_vendor',
    fieldKey: 'update_time',
    name: '更新时间',
    fieldType: 'Datetime',
    createTime: '2021-04-23T08:26:05+00:00',
    updateTime: '2021-04-23T08:26:05+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_vendor',
    fieldKey: 'competitors',
    name: '竞品',
    fieldType: 'Tags',
    createTime: '2021-04-23T09:25:04+00:00',
    updateTime: '2021-04-23T09:25:04+00:00',
    options: [
      {
        label: '不详',
        value: 1,
        restraintValueMap: {},
      },
      {
        label: '无',
        value: 2,
        restraintValueMap: {},
      },
      {
        label: '其他',
        value: 3,
        restraintValueMap: {},
      },
      {
        label: '自研',
        value: 4,
        restraintValueMap: {},
      },
      {
        label: 'Zego',
        value: 5,
        restraintValueMap: {},
      },
      {
        label: '好未来直播云',
        value: 6,
        restraintValueMap: {},
      },
      {
        label: '腾讯',
        value: 7,
        restraintValueMap: {},
      },
      {
        label: '阿里',
        value: 8,
        restraintValueMap: {},
      },
      {
        label: '网易',
        value: 9,
        restraintValueMap: {},
      },
      {
        label: '拓客云',
        value: 10,
        restraintValueMap: {},
      },
      {
        label: 'ClassIn',
        value: 11,
        restraintValueMap: {},
      },
      {
        label: 'JustTalk',
        value: 12,
        restraintValueMap: {},
      },
      {
        label: 'Zoom',
        value: 13,
        restraintValueMap: {},
      },
      {
        label: '七牛',
        value: 14,
        restraintValueMap: {},
      },
      {
        label: '清流鼎点',
        value: 15,
        restraintValueMap: {},
      },
      {
        label: '三体云',
        value: 16,
        restraintValueMap: {},
      },
      {
        label: '环信',
        value: 17,
        restraintValueMap: {},
      },
      {
        label: 'YY',
        value: 18,
        restraintValueMap: {},
      },
      {
        label: '融云',
        value: 19,
        restraintValueMap: {},
      },
    ],
    value2LabelMap: {
      '1': '不详',
      '2': '无',
      '3': '其他',
      '4': '自研',
      '5': 'Zego',
      '6': '好未来直播云',
      '7': '腾讯',
      '8': '阿里',
      '9': '网易',
      '10': '拓客云',
      '11': 'ClassIn',
      '12': 'JustTalk',
      '13': 'Zoom',
      '14': '七牛',
      '15': '清流鼎点',
      '16': '三体云',
      '17': '环信',
      '18': 'YY',
      '19': '融云',
    },
  },
  {
    modelKey: 'csdc_vendor',
    fieldKey: 'competitor_info',
    name: '竞品动态描述',
    fieldType: 'MultipleLinesText',
    createTime: '2021-04-23T09:25:36+00:00',
    updateTime: '2021-04-23T09:25:36+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_vendor',
    fieldKey: 'os',
    name: 'OS',
    fieldType: 'Tags',
    createTime: '2021-04-23T09:26:50+00:00',
    updateTime: '2021-04-23T09:26:50+00:00',
    options: [
      {
        label: 'Android',
        value: 1,
        restraintValueMap: {},
      },
      {
        label: 'iOS',
        value: 2,
        restraintValueMap: {},
      },
      {
        label: 'Windows',
        value: 3,
        restraintValueMap: {},
      },
      {
        label: 'Mac',
        value: 4,
        restraintValueMap: {},
      },
      {
        label: 'Web',
        value: 5,
        restraintValueMap: {},
      },
    ],
    value2LabelMap: {
      '1': 'Android',
      '2': 'iOS',
      '3': 'Windows',
      '4': 'Mac',
      '5': 'Web',
    },
  },
  {
    modelKey: 'csdc_vendor',
    fieldKey: 'stakeholders',
    name: '项目人员架构',
    fieldType: 'MultipleLinesText',
    createTime: '2021-04-23T09:39:27+00:00',
    updateTime: '2021-04-23T09:39:27+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_vendor',
    fieldKey: 'function_unit',
    name: '功能模块组件',
    fieldType: 'Tags',
    createTime: '2021-04-23T09:42:38+00:00',
    updateTime: '2021-05-07T09:15:24+00:00',
    options: [
      {
        label: '白板动画',
        value: 1,
        restraintValueMap: {},
      },
      {
        label: '课前检测',
        value: 2,
        restraintValueMap: {},
      },
      {
        label: '服务端录制',
        value: 3,
        restraintValueMap: {},
      },
      {
        label: '云录制',
        value: 4,
        restraintValueMap: {},
      },
      {
        label: '监课端',
        value: 5,
        restraintValueMap: {},
      },
      {
        label: 'UI 状态提示',
        value: 6,
        restraintValueMap: {},
      },
      {
        label: 'Agora sdk log 收集',
        value: 7,
        restraintValueMap: {},
      },
      {
        label: '音频 Dump 收集',
        value: 8,
        restraintValueMap: {},
      },
      {
        label: '崩溃日志 收集',
        value: 9,
        restraintValueMap: {},
      },
      {
        label: '版本灰度发布/回滚',
        value: 10,
        restraintValueMap: {},
      },
      {
        label: '调试课堂',
        value: 11,
        restraintValueMap: {},
      },
      {
        label: '高级设置',
        value: 12,
        restraintValueMap: {},
      },
      {
        label: '工单系统',
        value: 13,
        restraintValueMap: {},
      },
    ],
    value2LabelMap: {
      '1': '白板动画',
      '2': '课前检测',
      '3': '服务端录制',
      '4': '云录制',
      '5': '监课端',
      '6': 'UI 状态提示',
      '7': 'Agora sdk log 收集',
      '8': '音频 Dump 收集',
      '9': '崩溃日志 收集',
      '10': '版本灰度发布/回滚',
      '11': '调试课堂',
      '12': '高级设置',
      '13': '工单系统',
    },
  },
  {
    modelKey: 'csdc_vendor',
    fieldKey: 'uid',
    name: 'UID特征',
    fieldType: 'MultipleLinesText',
    createTime: '2021-04-23T09:42:53+00:00',
    updateTime: '2021-04-23T09:42:53+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_vendor',
    fieldKey: 'tam',
    name: 'TAM ',
    fieldType: 'User',
    createTime: '2021-04-23T09:43:32+00:00',
    updateTime: '2021-04-23T09:43:32+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_vendor',
    fieldKey: 'sa',
    name: 'SA',
    fieldType: 'User',
    createTime: '2021-04-23T09:43:40+00:00',
    updateTime: '2021-04-23T09:43:40+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_vendor',
    fieldKey: 'cse',
    name: 'CSE',
    fieldType: 'User',
    createTime: '2021-04-23T09:43:49+00:00',
    updateTime: '2021-04-23T09:43:49+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_vendor',
    fieldKey: 'strategy',
    name: 'Q2防守策略',
    fieldType: 'MultipleLinesText',
    createTime: '2021-04-23T09:44:39+00:00',
    updateTime: '2021-04-23T09:44:39+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_vendor',
    fieldKey: 'project_comments',
    name: '项目备注',
    fieldType: 'MultipleLinesText',
    createTime: '2021-04-23T09:45:03+00:00',
    updateTime: '2021-04-23T09:45:03+00:00',
    options: [],
    value2LabelMap: {},
  },
];
export const agora_vid_usage_cube_wow_v: ArcherDataModel[] = [
  {
    modelKey: 'agora_vid_usage_cube_wow_v',
    fieldKey: 'current_week_usage',
    name: '本周用量',
    fieldType: 'Float',
    createTime: '2020-08-21T03:30:01+00:00',
    updateTime: '2020-08-28T03:41:51+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'agora_vid_usage_cube_wow_v',
    fieldKey: 'current_week_over_week',
    name: '本周环比',
    fieldType: 'Float',
    createTime: '2020-08-21T03:29:46+00:00',
    updateTime: '2020-08-28T03:41:48+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'agora_vid_usage_cube_wow_v',
    fieldKey: 'last_week_usage',
    name: '上周用量',
    fieldType: 'Float',
    createTime: '2020-08-21T07:58:07+00:00',
    updateTime: '2020-08-28T03:41:44+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'agora_vid_usage_cube_wow_v',
    fieldKey: 'last_week_over_week',
    name: '上周环比',
    fieldType: 'Float',
    createTime: '2020-08-21T07:57:27+00:00',
    updateTime: '2020-08-28T03:41:39+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'agora_vid_usage_cube_wow_v',
    fieldKey: '__rid',
    name: '自增 ID',
    fieldType: 'Integer',
    createTime: '2020-08-21T03:29:14+00:00',
    updateTime: '2020-08-21T03:29:14+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'agora_vid_usage_cube_wow_v',
    fieldKey: 'author',
    name: '创建者邮箱',
    fieldType: 'User',
    createTime: '2020-08-21T03:29:14+00:00',
    updateTime: '2020-08-21T03:29:14+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'agora_vid_usage_cube_wow_v',
    fieldKey: 'update_author',
    name: '更新者邮箱',
    fieldType: 'User',
    createTime: '2020-08-21T03:29:14+00:00',
    updateTime: '2020-08-21T03:29:14+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'agora_vid_usage_cube_wow_v',
    fieldKey: 'create_time',
    name: '创建时间',
    fieldType: 'Datetime',
    createTime: '2020-08-21T03:29:14+00:00',
    updateTime: '2020-08-21T03:29:14+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'agora_vid_usage_cube_wow_v',
    fieldKey: 'update_time',
    name: '更新时间',
    fieldType: 'Datetime',
    createTime: '2020-08-21T03:29:14+00:00',
    updateTime: '2020-08-21T03:29:14+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'agora_vid_usage_cube_wow_v',
    fieldKey: 'vid',
    name: 'vid',
    fieldType: 'SingleLineText',
    createTime: '2020-08-21T07:58:20+00:00',
    updateTime: '2020-08-21T07:58:20+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'agora_vid_usage_cube_wow_v',
    fieldKey: 'week_before_last_week_usage',
    name: '上上周用量',
    fieldType: 'Float',
    createTime: '2020-08-21T07:58:41+00:00',
    updateTime: '2020-08-21T07:58:41+00:00',
    options: [],
    value2LabelMap: {},
  },
];
export const csdc_vender_sdk_usage: ArcherDataModel[] = [
  {
    modelKey: 'csdc_vender_sdk_usage',
    fieldKey: 'vid',
    name: 'VID',
    fieldType: 'Integer',
    createTime: '2021-04-04T13:42:14+00:00',
    updateTime: '2021-04-05T09:13:30+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_vender_sdk_usage',
    fieldKey: 'cid',
    name: 'CID',
    fieldType: 'Integer',
    createTime: '2021-04-05T09:13:09+00:00',
    updateTime: '2021-04-05T09:13:27+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_vender_sdk_usage',
    fieldKey: 'android_max_usage_ver',
    name: 'Android最大用量版本',
    fieldType: 'SingleLineText',
    createTime: '2021-04-04T13:47:25+00:00',
    updateTime: '2021-04-04T13:53:31+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_vender_sdk_usage',
    fieldKey: 'android_max_ver',
    name: 'Android最高版本',
    fieldType: 'SingleLineText',
    createTime: '2021-04-04T13:47:40+00:00',
    updateTime: '2021-04-04T13:53:30+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_vender_sdk_usage',
    fieldKey: 'ios_max_usage_ver',
    name: 'iOS最大用量版本',
    fieldType: 'SingleLineText',
    createTime: '2021-04-04T13:48:08+00:00',
    updateTime: '2021-04-04T13:53:28+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_vender_sdk_usage',
    fieldKey: 'ios_max_ver',
    name: 'iOS最高版本',
    fieldType: 'SingleLineText',
    createTime: '2021-04-04T13:48:23+00:00',
    updateTime: '2021-04-04T13:53:26+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_vender_sdk_usage',
    fieldKey: 'windows_max_usage_ver',
    name: 'Win最大用量版本',
    fieldType: 'SingleLineText',
    createTime: '2021-04-04T13:49:31+00:00',
    updateTime: '2021-04-04T13:53:24+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_vender_sdk_usage',
    fieldKey: 'windows_max_ver',
    name: 'Win最高版本',
    fieldType: 'SingleLineText',
    createTime: '2021-04-04T13:50:44+00:00',
    updateTime: '2021-04-04T13:53:22+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_vender_sdk_usage',
    fieldKey: 'mac_max_usage_ver',
    name: 'MacOS最大用量版本',
    fieldType: 'SingleLineText',
    createTime: '2021-04-04T13:52:16+00:00',
    updateTime: '2021-04-04T13:53:21+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_vender_sdk_usage',
    fieldKey: 'mac_max_ver',
    name: 'MacOS最高版本',
    fieldType: 'SingleLineText',
    createTime: '2021-04-04T13:52:33+00:00',
    updateTime: '2021-04-04T13:53:19+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_vender_sdk_usage',
    fieldKey: 'web_max_usage_ver',
    name: 'Web最大用量版本',
    fieldType: 'SingleLineText',
    createTime: '2021-04-04T13:48:57+00:00',
    updateTime: '2021-04-04T13:53:15+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_vender_sdk_usage',
    fieldKey: 'web_max_ver',
    name: 'Web最高版本',
    fieldType: 'SingleLineText',
    createTime: '2021-04-04T13:49:13+00:00',
    updateTime: '2021-04-04T13:53:12+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_vender_sdk_usage',
    fieldKey: 'linux_max_usage_ver',
    name: 'Linux最大用量版本',
    fieldType: 'SingleLineText',
    createTime: '2021-04-04T13:51:40+00:00',
    updateTime: '2021-04-04T13:53:01+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_vender_sdk_usage',
    fieldKey: 'linux_max_ver',
    name: 'Linux最高版本',
    fieldType: 'SingleLineText',
    createTime: '2021-04-04T13:52:00+00:00',
    updateTime: '2021-04-04T13:52:59+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_vender_sdk_usage',
    fieldKey: '__rid',
    name: '自增 ID',
    fieldType: 'Integer',
    createTime: '2021-04-04T13:39:20+00:00',
    updateTime: '2021-04-04T13:39:20+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_vender_sdk_usage',
    fieldKey: 'author',
    name: '创建者邮箱',
    fieldType: 'User',
    createTime: '2021-04-04T13:39:20+00:00',
    updateTime: '2021-04-04T13:39:20+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_vender_sdk_usage',
    fieldKey: 'update_author',
    name: '更新者邮箱',
    fieldType: 'User',
    createTime: '2021-04-04T13:39:20+00:00',
    updateTime: '2021-04-04T13:39:20+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_vender_sdk_usage',
    fieldKey: 'create_time',
    name: '创建时间',
    fieldType: 'Datetime',
    createTime: '2021-04-04T13:39:20+00:00',
    updateTime: '2021-04-04T13:39:20+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_vender_sdk_usage',
    fieldKey: 'update_time',
    name: '更新时间',
    fieldType: 'Datetime',
    createTime: '2021-04-04T13:39:20+00:00',
    updateTime: '2021-04-04T13:39:20+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_vender_sdk_usage',
    fieldKey: 'use_case',
    name: '场景',
    fieldType: 'SingleLineText',
    createTime: '2021-04-23T10:07:39+00:00',
    updateTime: '2021-04-23T10:08:13+00:00',
    options: [],
    value2LabelMap: {},
  },
];
export const csdc_vendor_jira_list: ArcherDataModel[] = [
  {
    modelKey: 'csdc_vendor_jira_list',
    fieldKey: '__rid',
    name: '自增 ID',
    fieldType: 'Integer',
    createTime: '2021-06-09T03:24:18+00:00',
    updateTime: '2021-06-09T03:24:18+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_vendor_jira_list',
    fieldKey: 'author',
    name: '创建者邮箱',
    fieldType: 'User',
    createTime: '2021-06-09T03:24:18+00:00',
    updateTime: '2021-06-09T03:24:18+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_vendor_jira_list',
    fieldKey: 'update_author',
    name: '更新者邮箱',
    fieldType: 'User',
    createTime: '2021-06-09T03:24:18+00:00',
    updateTime: '2021-06-09T03:24:18+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_vendor_jira_list',
    fieldKey: 'create_time',
    name: '创建时间',
    fieldType: 'Datetime',
    createTime: '2021-06-09T03:24:18+00:00',
    updateTime: '2021-06-09T03:24:18+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_vendor_jira_list',
    fieldKey: 'update_time',
    name: '更新时间',
    fieldType: 'Datetime',
    createTime: '2021-06-09T03:24:18+00:00',
    updateTime: '2021-06-09T03:24:18+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_vendor_jira_list',
    fieldKey: 'company_id',
    name: 'company_id',
    fieldType: 'Integer',
    createTime: '2021-06-09T03:27:52+00:00',
    updateTime: '2021-06-09T03:27:52+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_vendor_jira_list',
    fieldKey: 'csd_bug_unresolved_list',
    name: 'csd_bug_unresolved_list',
    fieldType: 'MultipleLinesText',
    createTime: '2021-06-09T03:28:51+00:00',
    updateTime: '2021-06-09T03:28:51+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_vendor_jira_list',
    fieldKey: 'csd_bug_unresolved_amount',
    name: 'csd_bug_unresolved_amount',
    fieldType: 'Integer',
    createTime: '2021-06-09T03:29:14+00:00',
    updateTime: '2021-06-09T03:29:14+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_vendor_jira_list',
    fieldKey: 'csd_newfeature_unresolved_list',
    name: 'csd_newfeature_unresolved_list',
    fieldType: 'MultipleLinesText',
    createTime: '2021-06-09T03:29:31+00:00',
    updateTime: '2021-06-09T03:29:31+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_vendor_jira_list',
    fieldKey: 'csd_newfeature_unresolved_amount',
    name: 'csd_newfeature_unresolved_amount',
    fieldType: 'Integer',
    createTime: '2021-06-09T03:29:43+00:00',
    updateTime: '2021-06-09T03:29:43+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_vendor_jira_list',
    fieldKey: 'vendor_id',
    name: 'vendor_id',
    fieldType: 'SingleLineText',
    createTime: '2021-06-09T03:32:53+00:00',
    updateTime: '2021-06-09T03:32:53+00:00',
    options: [],
    value2LabelMap: {},
  },
];
export const csdc_vendor_product_types: ArcherDataModel[] = [
  {
    modelKey: 'csdc_vendor_product_types',
    fieldKey: '__rid',
    name: '自增 ID',
    fieldType: 'Integer',
    createTime: '2021-02-19T12:54:27+00:00',
    updateTime: '2021-02-19T12:54:27+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_vendor_product_types',
    fieldKey: 'author',
    name: '创建者邮箱',
    fieldType: 'User',
    createTime: '2021-02-19T12:54:27+00:00',
    updateTime: '2021-02-19T12:54:27+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_vendor_product_types',
    fieldKey: 'update_author',
    name: '更新者邮箱',
    fieldType: 'User',
    createTime: '2021-02-19T12:54:27+00:00',
    updateTime: '2021-02-19T12:54:27+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_vendor_product_types',
    fieldKey: 'create_time',
    name: '创建时间',
    fieldType: 'Datetime',
    createTime: '2021-02-19T12:54:27+00:00',
    updateTime: '2021-02-19T12:54:27+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_vendor_product_types',
    fieldKey: 'update_time',
    name: '更新时间',
    fieldType: 'Datetime',
    createTime: '2021-02-19T12:54:27+00:00',
    updateTime: '2021-02-19T12:54:27+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_vendor_product_types',
    fieldKey: 'uap_configured_product_types',
    name: '开通云服务',
    fieldType: 'Tags',
    createTime: '2021-02-19T13:25:03+00:00',
    updateTime: '2021-02-19T13:25:03+00:00',
    options: [
      {
        label: 'Mini Program',
        value: 1,
        restraintValueMap: {},
      },
      {
        label: 'Server Streaming',
        value: 2,
        restraintValueMap: {},
      },
      {
        label: 'Push Streaming2.0',
        value: 3,
        restraintValueMap: {},
      },
      {
        label: 'Cloud Proxy',
        value: 4,
        restraintValueMap: {},
      },
      {
        label: 'WebRTC Proxy',
        value: 5,
        restraintValueMap: {},
      },
      {
        label: 'Cross Channel Host-in',
        value: 6,
        restraintValueMap: {},
      },
      {
        label: 'Cloud Recording',
        value: 7,
        restraintValueMap: {},
      },
      {
        label: 'Push Streaming3.0',
        value: 8,
        restraintValueMap: {},
      },
      {
        label: 'Cloud Player',
        value: 9,
        restraintValueMap: {},
      },
      {
        label: 'RTMP Converter',
        value: 10,
        restraintValueMap: {},
      },
      {
        label: 'RTMP Pusher',
        value: 11,
        restraintValueMap: {},
      },
      {
        label: 'Cloud Simulcast',
        value: 12,
        restraintValueMap: {},
      },
      {
        label: 'Image Moderation API',
        value: 13,
        restraintValueMap: {},
      },
      {
        label: 'Cloud Transcoder',
        value: 14,
        restraintValueMap: {},
      },
    ],
    value2LabelMap: {
      '1': 'Mini Program',
      '2': 'Server Streaming',
      '3': 'Push Streaming2.0',
      '4': 'Cloud Proxy',
      '5': 'WebRTC Proxy',
      '6': 'Cross Channel Host-in',
      '7': 'Cloud Recording',
      '8': 'Push Streaming3.0',
      '9': 'Cloud Player',
      '10': 'RTMP Converter',
      '11': 'RTMP Pusher',
      '12': 'Cloud Simulcast',
      '13': 'Image Moderation API',
      '14': 'Cloud Transcoder',
    },
  },
  {
    modelKey: 'csdc_vendor_product_types',
    fieldKey: 'used_inweek_product_types',
    name: '有用量云服务',
    fieldType: 'Tags',
    createTime: '2021-02-19T13:38:01+00:00',
    updateTime: '2021-02-19T13:38:01+00:00',
    options: [
      {
        label: 'Native',
        value: 1,
        restraintValueMap: {},
      },
      {
        label: 'PSTN',
        value: 2,
        restraintValueMap: {},
      },
      {
        label: 'OnPremiseRecording',
        value: 3,
        restraintValueMap: {},
      },
      {
        label: 'Mosaic',
        value: 4,
        restraintValueMap: {},
      },
      {
        label: 'WebRTS',
        value: 5,
        restraintValueMap: {},
      },
      {
        label: 'LbPublish',
        value: 6,
        restraintValueMap: {},
      },
      {
        label: 'LbInject',
        value: 7,
        restraintValueMap: {},
      },
      {
        label: 'MiniApp',
        value: 8,
        restraintValueMap: {},
      },
      {
        label: 'ServerStreaming',
        value: 9,
        restraintValueMap: {},
      },
      {
        label: 'CloudRecording',
        value: 10,
        restraintValueMap: {},
      },
      {
        label: 'MixStreaming',
        value: 11,
        restraintValueMap: {},
      },
      {
        label: 'RawStreaming',
        value: 12,
        restraintValueMap: {},
      },
      {
        label: 'InjectStreaming',
        value: 13,
        restraintValueMap: {},
      },
      {
        label: 'Transmission',
        value: 14,
        restraintValueMap: {},
      },
      {
        label: 'MediaServer',
        value: 15,
        restraintValueMap: {},
      },
      {
        label: 'AudioModeration',
        value: 16,
        restraintValueMap: {},
      },
      {
        label: 'WebRTC',
        value: 17,
        restraintValueMap: {},
      },
      {
        label: 'Flv',
        value: 21,
        restraintValueMap: {},
      },
      {
        label: 'RTSA',
        value: 22,
        restraintValueMap: {},
      },
      {
        label: 'LocalProxy',
        value: 23,
        restraintValueMap: {},
      },
      {
        label: 'ProxyVos',
        value: 24,
        restraintValueMap: {},
      },
      {
        label: 'WebRTCOpenPlatform',
        value: 25,
        restraintValueMap: {},
      },
      {
        label: 'Snapshot',
        value: 26,
        restraintValueMap: {},
      },
      {
        label: 'VideoModeration',
        value: 27,
        restraintValueMap: {},
      },
      {
        label: 'CloudPlayer',
        value: 28,
        restraintValueMap: {},
      },
      {
        label: 'RTMPConverter',
        value: 30,
        restraintValueMap: {},
      },
      {
        label: 'ServerSDKSend',
        value: 31,
        restraintValueMap: {},
      },
      {
        label: 'ServerSDKReceive',
        value: 32,
        restraintValueMap: {},
      },
      {
        label: 'ServerSDKGate',
        value: 33,
        restraintValueMap: {},
      },
      {
        label: 'RTMPPusher',
        value: 34,
        restraintValueMap: {},
      },
    ],
    value2LabelMap: {
      '1': 'Native',
      '2': 'PSTN',
      '3': 'OnPremiseRecording',
      '4': 'Mosaic',
      '5': 'WebRTS',
      '6': 'LbPublish',
      '7': 'LbInject',
      '8': 'MiniApp',
      '9': 'ServerStreaming',
      '10': 'CloudRecording',
      '11': 'MixStreaming',
      '12': 'RawStreaming',
      '13': 'InjectStreaming',
      '14': 'Transmission',
      '15': 'MediaServer',
      '16': 'AudioModeration',
      '17': 'WebRTC',
      '21': 'Flv',
      '22': 'RTSA',
      '23': 'LocalProxy',
      '24': 'ProxyVos',
      '25': 'WebRTCOpenPlatform',
      '26': 'Snapshot',
      '27': 'VideoModeration',
      '28': 'CloudPlayer',
      '30': 'RTMPConverter',
      '31': 'ServerSDKSend',
      '32': 'ServerSDKReceive',
      '33': 'ServerSDKGate',
      '34': 'RTMPPusher',
    },
  },
  {
    modelKey: 'csdc_vendor_product_types',
    fieldKey: 'company_id',
    name: 'company_id',
    fieldType: 'Integer',
    createTime: '2021-02-20T04:24:56+00:00',
    updateTime: '2021-02-20T04:24:56+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_vendor_product_types',
    fieldKey: 'vendor_id',
    name: 'vendor_id',
    fieldType: 'Integer',
    createTime: '2021-02-20T04:25:18+00:00',
    updateTime: '2021-02-20T04:25:18+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_vendor_product_types',
    fieldKey: 'rtm_weekly_usage',
    name: 'RTM周用量',
    fieldType: 'Integer',
    createTime: '2021-06-09T03:10:03+00:00',
    updateTime: '2021-06-09T03:10:03+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_vendor_product_types',
    fieldKey: 'rtm_used_or_not',
    name: 'RTM是否有周用量',
    fieldType: 'Tags',
    createTime: '2021-06-09T03:10:29+00:00',
    updateTime: '2021-06-09T03:10:29+00:00',
    options: [
      {
        label: 'YES',
        value: 1,
        restraintValueMap: {},
      },
      {
        label: 'NO',
        value: 2,
        restraintValueMap: {},
      },
    ],
    value2LabelMap: {
      '1': 'YES',
      '2': 'NO',
    },
  },
  {
    modelKey: 'csdc_vendor_product_types',
    fieldKey: 'ncs_configured_types',
    name: '开通的NCS消息类型',
    fieldType: 'Tags',
    createTime: '2021-06-09T03:11:41+00:00',
    updateTime: '2021-06-09T03:11:41+00:00',
    options: [
      {
        label: 'Real-Time Communication',
        value: 1,
        restraintValueMap: {},
      },
      {
        label: 'RTMP Converter',
        value: 2,
        restraintValueMap: {},
      },
      {
        label: 'Push Stream to CDN 3.0',
        value: 3,
        restraintValueMap: {},
      },
      {
        label: 'Cloud Simulcast',
        value: 4,
        restraintValueMap: {},
      },
      {
        label: 'Cloud Recording',
        value: 5,
        restraintValueMap: {},
      },
      {
        label: 'Cloud Player',
        value: 6,
        restraintValueMap: {},
      },
    ],
    value2LabelMap: {
      '1': 'Real-Time Communication',
      '2': 'RTMP Converter',
      '3': 'Push Stream to CDN 3.0',
      '4': 'Cloud Simulcast',
      '5': 'Cloud Recording',
      '6': 'Cloud Player',
    },
  },
  {
    modelKey: 'csdc_vendor_product_types',
    fieldKey: 'ad_products_used_inweek_app_types',
    name: '有用量的第三方框架',
    fieldType: 'Tags',
    createTime: '2021-06-09T03:13:54+00:00',
    updateTime: '2021-06-09T03:13:54+00:00',
    options: [
      {
        label: 'flutter',
        value: 1,
        restraintValueMap: {},
      },
      {
        label: 'c#',
        value: 2,
        restraintValueMap: {},
      },
      {
        label: 'uniapp',
        value: 3,
        restraintValueMap: {},
      },
      {
        label: 'python',
        value: 4,
        restraintValueMap: {},
      },
      {
        label: 'unity',
        value: 5,
        restraintValueMap: {},
      },
      {
        label: 'react_native',
        value: 6,
        restraintValueMap: {},
      },
      {
        label: 'electron',
        value: 7,
        restraintValueMap: {},
      },
      {
        label: 'cocos_creator',
        value: 8,
        restraintValueMap: {},
      },
      {
        label: 'cef',
        value: 9,
        restraintValueMap: {},
      },
      {
        label: 'cocos',
        value: 10,
        restraintValueMap: {},
      },
    ],
    value2LabelMap: {
      '1': 'flutter',
      '2': 'c#',
      '3': 'uniapp',
      '4': 'python',
      '5': 'unity',
      '6': 'react_native',
      '7': 'electron',
      '8': 'cocos_creator',
      '9': 'cef',
      '10': 'cocos',
    },
  },
];

export const wide_company: ArcherDataModel[] = [
  {
    modelKey: 'wide_company',
    fieldKey: 'sales_email',
    name: '商务',
    fieldType: 'SingleLineText',
    createTime: '2021-04-23T10:07:39+00:00',
    updateTime: '2021-04-23T10:08:13+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'wide_company',
    fieldKey: 'company_last_month_mr_level',
    name: '收入等级',
    fieldType: 'SingleLineText',
    createTime: '2021-04-23T10:07:39+00:00',
    updateTime: '2021-04-23T10:08:13+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'wide_company',
    fieldKey: 'sp_company_owner_1_email',
    name: 'owner',
    fieldType: 'SingleLineText',
    createTime: '2021-04-23T10:07:39+00:00',
    updateTime: '2021-04-23T10:08:13+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'wide_company',
    fieldKey: 'sp_company_backup_1_email',
    name: 'backup',
    fieldType: 'SingleLineText',
    createTime: '2021-04-23T10:07:39+00:00',
    updateTime: '2021-04-23T10:08:13+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'wide_company',
    fieldKey: 'sp_company_backup_2_email',
    name: 'backup2',
    fieldType: 'SingleLineText',
    createTime: '2021-04-23T10:07:39+00:00',
    updateTime: '2021-04-23T10:08:13+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'wide_company',
    fieldKey: 'current_sp_support_level_str',
    name: '服务等级',
    fieldType: 'SingleLineText',
    createTime: '2021-04-23T10:07:39+00:00',
    updateTime: '2021-04-23T10:08:13+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'wide_company',
    fieldKey: 'company_name',
    name: '公司名',
    fieldType: 'SingleLineText',
    createTime: '2021-04-23T10:07:39+00:00',
    updateTime: '2021-04-23T10:08:13+00:00',
    options: [],
    value2LabelMap: {},
  },
];

export const agora_cid_usage_cube_week_v: ArcherDataModel[] = [];
export const csdc_company_sdk_usage: ArcherDataModel[] = [
  {
    modelKey: 'csdc_company_sdk_usage',
    fieldKey: 'vid',
    name: 'VID',
    fieldType: 'Integer',
    createTime: '2021-04-04T13:42:14+00:00',
    updateTime: '2021-04-05T09:13:30+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_company_sdk_usage',
    fieldKey: 'cid',
    name: 'CID',
    fieldType: 'Integer',
    createTime: '2021-04-05T09:13:09+00:00',
    updateTime: '2021-04-05T09:13:27+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_company_sdk_usage',
    fieldKey: 'android_max_usage_ver',
    name: 'Android最大用量版本',
    fieldType: 'SingleLineText',
    createTime: '2021-04-04T13:47:25+00:00',
    updateTime: '2021-04-04T13:53:31+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_company_sdk_usage',
    fieldKey: 'android_max_ver',
    name: 'Android最高版本',
    fieldType: 'SingleLineText',
    createTime: '2021-04-04T13:47:40+00:00',
    updateTime: '2021-04-04T13:53:30+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_company_sdk_usage',
    fieldKey: 'ios_max_usage_ver',
    name: 'iOS最大用量版本',
    fieldType: 'SingleLineText',
    createTime: '2021-04-04T13:48:08+00:00',
    updateTime: '2021-04-04T13:53:28+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_company_sdk_usage',
    fieldKey: 'ios_max_ver',
    name: 'iOS最高版本',
    fieldType: 'SingleLineText',
    createTime: '2021-04-04T13:48:23+00:00',
    updateTime: '2021-04-04T13:53:26+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_company_sdk_usage',
    fieldKey: 'windows_max_usage_ver',
    name: 'Win最大用量版本',
    fieldType: 'SingleLineText',
    createTime: '2021-04-04T13:49:31+00:00',
    updateTime: '2021-04-04T13:53:24+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_company_sdk_usage',
    fieldKey: 'windows_max_ver',
    name: 'Win最高版本',
    fieldType: 'SingleLineText',
    createTime: '2021-04-04T13:50:44+00:00',
    updateTime: '2021-04-04T13:53:22+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_company_sdk_usage',
    fieldKey: 'mac_max_usage_ver',
    name: 'MacOS最大用量版本',
    fieldType: 'SingleLineText',
    createTime: '2021-04-04T13:52:16+00:00',
    updateTime: '2021-04-04T13:53:21+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_company_sdk_usage',
    fieldKey: 'mac_max_ver',
    name: 'MacOS最高版本',
    fieldType: 'SingleLineText',
    createTime: '2021-04-04T13:52:33+00:00',
    updateTime: '2021-04-04T13:53:19+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_company_sdk_usage',
    fieldKey: 'web_max_usage_ver',
    name: 'Web最大用量版本',
    fieldType: 'SingleLineText',
    createTime: '2021-04-04T13:48:57+00:00',
    updateTime: '2021-04-04T13:53:15+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_company_sdk_usage',
    fieldKey: 'web_max_ver',
    name: 'Web最高版本',
    fieldType: 'SingleLineText',
    createTime: '2021-04-04T13:49:13+00:00',
    updateTime: '2021-04-04T13:53:12+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_company_sdk_usage',
    fieldKey: 'linux_max_usage_ver',
    name: 'Linux最大用量版本',
    fieldType: 'SingleLineText',
    createTime: '2021-04-04T13:51:40+00:00',
    updateTime: '2021-04-04T13:53:01+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_company_sdk_usage',
    fieldKey: 'linux_max_ver',
    name: 'Linux最高版本',
    fieldType: 'SingleLineText',
    createTime: '2021-04-04T13:52:00+00:00',
    updateTime: '2021-04-04T13:52:59+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_company_sdk_usage',
    fieldKey: '__rid',
    name: '自增 ID',
    fieldType: 'Integer',
    createTime: '2021-04-04T13:39:20+00:00',
    updateTime: '2021-04-04T13:39:20+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_company_sdk_usage',
    fieldKey: 'author',
    name: '创建者邮箱',
    fieldType: 'User',
    createTime: '2021-04-04T13:39:20+00:00',
    updateTime: '2021-04-04T13:39:20+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_company_sdk_usage',
    fieldKey: 'update_author',
    name: '更新者邮箱',
    fieldType: 'User',
    createTime: '2021-04-04T13:39:20+00:00',
    updateTime: '2021-04-04T13:39:20+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_company_sdk_usage',
    fieldKey: 'create_time',
    name: '创建时间',
    fieldType: 'Datetime',
    createTime: '2021-04-04T13:39:20+00:00',
    updateTime: '2021-04-04T13:39:20+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_company_sdk_usage',
    fieldKey: 'update_time',
    name: '更新时间',
    fieldType: 'Datetime',
    createTime: '2021-04-04T13:39:20+00:00',
    updateTime: '2021-04-04T13:39:20+00:00',
    options: [],
    value2LabelMap: {},
  },
  {
    modelKey: 'csdc_company_sdk_usage',
    fieldKey: 'use_case',
    name: '场景',
    fieldType: 'SingleLineText',
    createTime: '2021-04-23T10:07:39+00:00',
    updateTime: '2021-04-23T10:08:13+00:00',
    options: [],
    value2LabelMap: {},
  },
];
export interface ArcherDataModelDic {
  [key: string]: ArcherDataModel;
}
const generalArcherModelDic = (list: ArcherDataModel[]): ArcherDataModelDic => {
  const result: ArcherDataModelDic = {};
  list.forEach((v) => {
    result[v.fieldKey] = v;
  });
  return result;
};

// 原数据对应的字典
export const csdc_company_dic = generalArcherModelDic(csdc_company);
export const wide_company_dic = generalArcherModelDic(wide_company);
export const csdc_company_jira_list_dic = generalArcherModelDic(csdc_company_jira_list);
export const agora_cid_usage_cube_week_v_dic = generalArcherModelDic(agora_cid_usage_cube_week_v);
export const csdc_company_product_types_dic = generalArcherModelDic(csdc_company_product_types);
export const csdc_company_sdk_usage_dic = generalArcherModelDic(csdc_company_sdk_usage);
export const csdc_vendor_dic = generalArcherModelDic(csdc_vendor);
export const agora_vid_usage_cube_wow_v_dic = generalArcherModelDic(agora_vid_usage_cube_wow_v);
export const csdc_vender_sdk_usage_dic = generalArcherModelDic(csdc_vender_sdk_usage);
export const csdc_vendor_jira_list_dic = generalArcherModelDic(csdc_vendor);
export const csdc_vendor_product_types_dic = generalArcherModelDic(csdc_vendor_product_types);

const archerModels: { [key: string]: { [key: string]: ArcherDataModel } } = {
  wide_company: wide_company_dic,
  csdc_company_jira_list: csdc_company_jira_list_dic,
  agora_cid_usage_cube_week_v: agora_cid_usage_cube_week_v_dic,
  csdc_company_product_types: csdc_company_product_types_dic,
  csdc_company_sdk_usage: csdc_company_sdk_usage_dic,
  csdc_company: csdc_company_dic,
  agora_vid_usage_cube_wow_v: agora_vid_usage_cube_wow_v_dic,
  csdc_vender_sdk_usage: csdc_vender_sdk_usage_dic,
  csdc_vendor_jira_list: csdc_vendor_jira_list_dic,
  csdc_vendor_product_types: csdc_vendor_product_types_dic,
};

// "TextEnum", "Tags"
type TranslationArcherVendorModel = {
  name: string;
  value: string | number | string[];
};

export const translationArcherVendorModel = <T = { [key: string]: string }>(
  data: T,
  showKeys: string[], // keyof T
  type: 'company' | 'vendor' = 'company'
): TranslationArcherVendorModel[] => {
  const keys = Object.keys(data);
  const result: TranslationArcherVendorModel[] = [];

  showKeys.forEach((k) => {
    // @ts-ignore
    const value = data[k];
    if (keys.includes(k)) {
      const d: TranslationArcherVendorModel = {
        name: k,
        value: '',
      };
      const ks = k.split('.');
      console.log(ks);
      console.log(type);
      console.log(csdc_company_dic);

      switch (ks.length) {
        case 1: {
          const model = type === 'company' ? csdc_company_dic[ks[0]] : csdc_vendor_dic[ks[0]];
          d.name = model.name;
          d.value = translationArcherVendorModelValue(value, model);
          break;
        }
        case 3: {
          const m1 = archerModels[ks[1]];
          if (m1 && m1[ks[2]]) {
            const model = m1[ks[2]];
            d.name = model.name;
            d.value = translationArcherVendorModelValue(value, model);
          }
          break;
        }
        default:
          break;
      }
      result.push(d);
    }
  });

  return result;
};

const translationArcherVendorModelValue = (v: CustomerInfoValue, model: ArcherDataModel): string | number | string[] => {
  if (!v) return '';
  try {
    switch (model.fieldType) {
      case 'Tags': {
        if (model.options.length == 0) {
          return [];
        }
        const optKeys = model.options.map((v) => Number(v.value));
        const max = Math.max(...optKeys);
        const value = Number(v);
        const result = [];
        for (let i = 0; 1 << i <= value && i <= max; ++i) {
          if (value & (1 << i)) {
            result.push(model.value2LabelMap[String(i)]);
          }
        }
        return result;
      }
      case 'TextEnum': {
        return model.value2LabelMap[String(v)];
      }
      default: {
        return v;
      }
    }
  } catch {
    return v;
  }
};
