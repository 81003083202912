<template>
  <van-form @submit="onSubmit" class="van-form">
    <van-field
      v-model="state.cid"
      name="cid"
      label="客户CID"
      placeholder="请填写客户的CID"
      :rules="[
        { required: true, message: '请填写客户CID' },
        {
          pattern: /^\d+$/,
          message: '请检查输入的CID格式(不要多出空格)',
          trigger: 'onChange',
        },
      ]"
    />
    <div style="margin: 5vh 16px">
      <van-button
        round
        block
        type="primary"
        native-type="submit"
        :loading="state.submiting"
        loading-text="提交中..."
      >
        提交
      </van-button>
    </div>
  </van-form>
</template>
<script lang="ts">
import { useStore } from "@/store";
import Notify from "vant/lib/notify";
import { defineComponent, reactive } from "vue";

export default defineComponent({
  setup() {
    const store = useStore();
    const state = reactive({
      cid: "",
      submiting: false,
      showPicker: false,
    });
    const onSubmit = async (values: any) => {
      if (!values.cid) {
        Notify({
          type: "danger",
          message: "无效CID",
        });
        return;
      }

      state.submiting = true;

      store.commit("setCID", values.cid);

      if (!store.state.chatID) {
        Notify({
          type: "warning",
          message: "未获取到当前群ID,暂时无法将CID与本群建立关联关系",
        });
      } else {
        store.dispatch("updateChatGroupInfo", {
          chatId: store.state.chatID,
          cid: values.cid,
        });
      }
      await store.dispatch("fetchCustomerArcherInfo", values.cid);
      await store.commit("jira/setLoading", true);
      await store.dispatch("jira/fetchJiraList", values.cid);
      await store.commit("jira/setLoading", false);

      state.submiting = false;
    };
    return {
      state,
      onSubmit,
    };
  },
});
</script>

<style lang="less" scoped>
@import "../../style/common.less";
.van-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .van-cell {
    font-size: 13px;
    color: #4e4f4f;
  }
  .van-cell::after {
    border-bottom: 1px solid #dcdcdc;
  }
}
</style>
