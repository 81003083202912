<template>
  <div>
    <div class="app-container">
      <div v-if="!state.showContent">
        <h3 class="welcome">欢迎,{{ state.userInfo?.name }}</h3>
        <input-cid />
      </div>
      <van-tabs
        class="nav"
        v-else
        line-height="1px"
        sticky
        title-active-color="#2c90f3"
      >
        <van-tab title="客户档案"><customer-company /></van-tab>
        <van-tab title="项目档案"><customer-vendor /></van-tab>
        <van-tab title="相关Jira"><jira /></van-tab>
        <van-tab title="工时记录"><work /></van-tab>
      </van-tabs>
    </div>
    <van-popup class="global_popup" :show="state.loading">
      <div class="global_loading">
        <van-loading type="spinner" size="50" />
      </div>
    </van-popup>
  </div>
</template>
<script lang="ts">
import InputCid from "@/components/InputCid/index.vue";
import CustomerCompany from "@/views/customer/company/index.vue";
import CustomerVendor from "@/views/customer/vendors/index.vue";
import Jira from "@/views/jira/index.vue";
import Work from "@/views/work/index.vue";
import { useStore } from "@/store";
import { agentConfig, getContext, getCurExternalChat } from "@/utils/wx";
import { computed, defineComponent, onMounted, reactive } from "vue";
import { CustomerVendorInfo } from "./views/customer/service";
import { Store } from "vuex";
import { getQueryVariable } from "./utils";
import { fetchAccount, fetchUserInfo, UserInfo } from "./views/service";
import { AllStateTypes } from "./store/types";
import { Notify } from "vant";

const weComInit = async function (store: Store<AllStateTypes>) {
  let userInfo: UserInfo;
  const code = getQueryVariable("code");
  if (code && code !== "") {
    userInfo = await fetchUserInfo(code);
  } else {
    userInfo = await fetchAccount();
  }
  // 登录成功
  if (userInfo && userInfo.userid) {
    store.commit("setUserInfo", userInfo);
    const url = window.location.href;
    agentConfig(url, ["getContext", "getCurExternalChat"])
      .then(() => {
        getContext()
          .then((context) => {
            const { entry } = context;
            if (entry === "group_chat_tools") {
              getCurExternalChat()
                .then((curExternalChat) => {
                  const { chatId } = curExternalChat; //返回当前客户群的群聊ID
                  store.commit("setChatID", chatId);
                  store.dispatch("fetchChatGroupInfo", chatId);
                })
                .catch((e) => {
                  Notify({
                    type: "danger",
                    message: `企业微信getCurExternalChat接口调用错误。\n ${JSON.stringify(
                      e
                    )}`,
                  });
                });
            }
          })
          .catch((e) => {
            Notify({
              type: "danger",
              message: `企业微信getContext接口调用错误。\n ${JSON.stringify(
                e
              )}`,
            });
          });
      })
      .catch((e) => {
        Notify({
          type: "danger",
          message: `企业微信agentConfig接口调用错误。\n ${JSON.stringify(e)}`,
        });
      });
  } else {
    if (code && code !== "") {
      Notify({ type: "danger", message: `获取用户信息失败。code:${code}` });
    }
  }
};
export default defineComponent({
  name: "App",
  components: {
    InputCid,
    CustomerCompany,
    CustomerVendor,
    Jira,
    Work,
  },
  setup() {
    const store = useStore();
    const state = reactive({
      loading: computed(() => store.state.loading),
      userInfo: computed(() => store.state.userInfo),
      showContent: computed(() => {
        return !!(
          store.state.CID &&
          store.state.CID !== "" &&
          store.state.userInfo?.userid &&
          store.state.companyInfo &&
          store.state.companyInfo.company_id
        );
      }),
    });
    onMounted(async () => {
      // wxAuth();
      store.subscribe(async (action, state) => {
        if (action.type === "setProjectInfo") {
          const projectInfo: CustomerVendorInfo[] = action.payload;
          // 重置 当前vid
          if (
            projectInfo &&
            projectInfo.length > 0 &&
            projectInfo.every((v) => v.vid !== state.VID)
          ) {
            store.commit("setVID", projectInfo[0].vid);
          }
        }
      });
      store.commit("setLoading", true);
      await weComInit(store);
      store.commit("setLoading", false);
    });
    return {
      state,
    };
  },
});
</script>
<style lang="less">
.app-container {
  :deep(.van-tabs__line) {
    background-color: #2c90f3 !important;
  }

  .nav {
    :deep(.van-tab) {
      font-size: 13px !important;
    }
  }
  .welcome {
    margin-top: 20vh;
    margin-bottom: 5vh;
  }
}
</style>
